import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

function AuthStep(props) {
    const reduxAppCMS = useSelector(state => state.reduxAppCMS.cms);

    const useStyles = makeStyles(() => ({
        box: {
            width: "49px",
            height: "8px",
            margin: "4px"
        },
        boxActive: {
            background: reduxAppCMS !== null ? reduxAppCMS["bt-danger"] : "#FF0000"
        },
        boxDisActive: {
            background: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white"
        },
        boxStart: {
            borderTopLeftRadius: "50px"
        },
        boxEnd: {
            borderTopRightRadius: "50px"
        }
    }));

    const classes = useStyles();

    const runCallback = (cb) => {
        return cb();
    };

    return (
        <>
            {
                runCallback(() => {
                    const items = [];
                    for (var i = 1; i <= props.total; i++) {
                        if (i === 1) {
                            items.push(<div className={[classes.box, classes.boxStart, props.currented >= 1 ? classes.boxActive : classes.boxDisActive].join(" ")} key={i}></div>)
                        } else if (i === props.total) {
                            items.push(<div className={[classes.box, classes.boxEnd, props.total === props.currented ? classes.boxActive : classes.boxDisActive].join(" ")} key={i}></div>)
                        } else {
                            items.push(<div className={[classes.box, i <= props.currented ? classes.boxActive : classes.boxDisActive].join(" ")} key={i}></div>)
                        }
                    }

                    return items;
                })
            }
        </>
    );
}

AuthStep.defaultProps = {
    total: 3,
    currented: 1
}


export default AuthStep;
