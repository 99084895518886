// import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Container } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Redux
import { setDataContact } from "./../../../../../@core/redux/actions/app/contact";

// Icon
import HomeIcon from '@material-ui/icons/Home';
import StyleIcon from '@material-ui/icons/Style';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ForumIcon from '@material-ui/icons/Forum';

export default function FooterActive({ play_game, pathUrl, reduxAppCMS }) {
    const history = useHistory();
    const dispatch = useDispatch();

    const useStyles = makeStyles((theme) => ({
        footerPosition: {
            position: "fixed",
            bottom: "0px",
            left: "0px",
            zIndex: "999",
            width: "100%"
        },
        footerContentPosition: {
            background: reduxAppCMS !== null ? reduxAppCMS["bg-foter"] : "white",
            boxShadow: "0 4px 8px 0 rgb(0 0 0 / 40%)",
            // filter: "drop-shadow(0px 0px 4px #1E1F24)",
            borderRadius: "15px 15px 0px 0px",
            borderTop: `6px solid ${reduxAppCMS !== null ? reduxAppCMS["bt-secondary"] : "#D1C4E9"}`
        },
        bagdeTop: {
            position: "absolute",
            top: "-2px",
            right: "22px"
        },
        bagdeTopcontent: {
            backgroundColor: "#dc3545",
            padding: "5px",
            borderRadius: "20px",
            [theme.breakpoints.down('sm')]: {
                padding: "2px",
            }
        },
        footerIconSize: {
            height: "54px",
            width: "54px",
            cursor: "pointer",
            [theme.breakpoints.down('xs')]: {
                height: "32px",
                width: "32px"
            }
        },
        footerIconCenterSize: {
            height: "54px",
            width: "54px",
            cursor: "pointer",
            // color: "white",
            [theme.breakpoints.down('xs')]: {
                height: "38px",
                width: "38px"
            }
        },
        footerIconCenterText: {
            textAlign: "center",
            fontSize: "18px",
            paddingTop: "4px",
            cursor: "pointer",
            fontWeight: "600",
            // color: "white",
            [theme.breakpoints.down('md')]: {
                fontSize: "16px"
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: "15px",
                paddingTop: "0px",
                marginTop: "-2px"
            }
        },
        footerIconText: {
            textAlign: "center",
            fontSize: "18px",
            paddingTop: "4px",
            cursor: "pointer",
            [theme.breakpoints.down('md')]: {
                fontSize: "16px"
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: "13px",
                paddingTop: "0px",
                marginTop: "-4px"
            }
        },
        footerList: {
            padding: "8px 0px",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-login"] : "#3C0077",
            '&:hover': {
                opacity: "0.8"
            }
        },
        footerListActive: {
            padding: "8px 0px",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-login"] : "#3C0077",
            opacity: "0.8"
            // background: "linear-gradient(180deg, #5829AB 0%, #380093 100%)"
        },
        footerListPosition: {
            position: "absolute",
            bottom: "-26px",
            left: "50%",
            transform: "translateX(-50%)",
            // width: "100%"
        },
        footerListCenter: {
            background: reduxAppCMS !== null ? reduxAppCMS["bt-confirm"] : "linear-gradient(180deg, #FFC700 0%, #FF8A00 100%)",
            color: "black",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: "60px",
            padding: "14px 0px",
            width: "116px",
            [theme.breakpoints.down('xs')]: {
                width: "86px"
            }
        }
    }));

    const classes = useStyles();

    const gotoPage = (page) => {
        history.push(page);
    };

    const openContact = () => {
        dispatch(setDataContact(true));
    };

    return (
        <>
            {
                (play_game !== "true" && play_game !== true) && (
                    <Container maxWidth="md" style={{ padding: "0px" }}>
                        <div className="relative">
                            <div className={classes.footerContentPosition}>
                                <Container maxWidth="lg" style={{ padding: "0px" }}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={2}>
                                            <div className={(pathUrl === "/") ? classes.footerListActive : classes.footerList} onClick={() => gotoPage("/")}>
                                                <div className="relative">
                                                    <HomeIcon className={classes.footerIconSize}></HomeIcon>
                                                    <div className={classes.footerIconText}>
                                                        {/* {t('sidebar.home')} */}
                                                        หน้าหลัก
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div className={(pathUrl === "/promotion") ? classes.footerListActive : classes.footerList} onClick={() => gotoPage("/promotion")}>
                                                <div className="relative">
                                                    <div className={classes.bagdeTop}>
                                                        <div className={classes.bagdeTopcontent}></div>
                                                    </div>
                                                    <StyleIcon className={classes.footerIconSize}></StyleIcon>
                                                    <div className={classes.footerIconText}>
                                                        โปรโมชั่น
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <div className="relative">
                                                <div className={classes.footerListPosition}>
                                                    <div className={classes.footerListCenter} onClick={() => gotoPage("/deposit/home")}>
                                                        <AccountBalanceWalletIcon className={classes.footerIconCenterSize}></AccountBalanceWalletIcon>
                                                        <div className={classes.footerIconCenterText}>
                                                            เติมเงิน
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div className={classes.footerList} onClick={() => gotoPage("/withdraw/home")}>
                                                <div className="relative">
                                                    <AccountBalanceIcon className={classes.footerIconSize}></AccountBalanceIcon>
                                                    <div className={classes.footerIconText}>
                                                        ถอนเงิน
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <div className={classes.footerList} onClick={openContact}>
                                                <div className="relative">
                                                    <ForumIcon className={classes.footerIconSize}></ForumIcon>
                                                    <div className={classes.footerIconText}>
                                                        ติดต่อเรา
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </div>
                        </div>
                    </Container>
                )
            }
        </>
    );
}