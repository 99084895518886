import { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { useSelector } from 'react-redux';

// Component
import ModalApp from "./Modal";

// CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import modalStyles from "./../assets/css/Modal.module.css";

const VipApp = (props) => {
    const reduxAppCMS = useSelector(state => state.reduxAppCMS.cms);
    const [dataList, setDataList] = useState(null);

    // const dataList = [
    //     {
    //         "id": 1,
    //         "img": "/images/game/profit/01.webp"
    //     },
    //     {
    //         "id": 2,
    //         "img": "/images/game/profit/02.webp"
    //     }
    // ];

    const settings = {
        infinite: true,
        // fade: false,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 600,
        autoplaySpeed: 6000,
        arrows: true
    };

    const onShow = (val) => {
        props.onSetVipData({ ...props.vipData, show: val });
    }

    useEffect(() => {
        if(reduxAppCMS !== null) {
            const dataArr = reduxAppCMS["image_vip"].split(",");
            // console.log(dataArr)
            setDataList(dataArr);
        }
    }, [reduxAppCMS]);

    return (
        <ModalApp show={props.vipData.show} onShow={onShow}>
            <div className={[modalStyles.boxTitle, "center"].join(" ")}>
                ระดับรางวัล
            </div>
            <br />
            <Slider {...settings}>
                {
                    dataList !== null && (
                        dataList.map((item, index) => {
                            return (
                                <div key={index}>
                                    <div style={{ padding: "0px 4px" }}>
                                        <img src={item} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" style={{ width: "100%", borderRadius: "16px" }} />
                                    </div>
                                </div>
                            )
                        })
                    )
                }
            </Slider>
            <br />
        </ModalApp>
    );
}

VipApp.defaultProps = {
    vipData: {
        tab: "VIP",
        show: false
    }
}

export default VipApp;