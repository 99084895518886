import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

// @utility
import currencyFormat from "./../../../../@utility/numbers/currencyFormat";

// Component
import ReloadBlance from "./ReloadBlance";

export default function NavbarMenu({ isLargeScreen }) {
    const homeData = useSelector(state => state.reduxHome.result);
    const { path } = useRouteMatch();
    const history = useHistory();
    const reduxAppCMS = useSelector(state => state.reduxAppCMS.cms);

    const useStyles = makeStyles((theme) => ({
        narbarPosition: {
            background: reduxAppCMS !== null ? reduxAppCMS["bt-info"] : "#68008C",
            padding: "4px",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white"
        },
        // iconPlus: {
        //     width: "48px",
        //     height: "48px",
        //     background: "#100025",
        //     borderRadius: "100%",
        //     [theme.breakpoints.down('xs')]: {
        //         width: "32px",
        //         height: "32px"
        //     }
        // },
        creditAlign: {
            background: reduxAppCMS !== null ? reduxAppCMS["bt-undefult"] : "black",
            border: `1px solid ${reduxAppCMS !== null ? reduxAppCMS["bt-undefult"] : "black"}`,
            boxSizing: "border-box",
            padding: "5px 0px",
            marginTop: "4px",
            marginBottom: "6px",
            borderRadius: "8px",
            color: reduxAppCMS !== null ? reduxAppCMS["bt-defult"] : "white",
            fontSize: "14px",
            cursor: "pointer",
            [theme.breakpoints.down('xs')]: {
                // width: "180px",
                padding: "3px 0px",
                marginTop: "3px"
            }
        },
        coinPosition: {
            position: "absolute",
            top: "50%",
            left: "-8px",
            transform: "translateY(-50%)",
            [theme.breakpoints.down('xs')]: {
                left: "-12px"
            }
        },
        coinSize: {
            width: "32px",
            height: "auto",
            [theme.breakpoints.down('xs')]: {
                width: "22px"
            }
        },
    }));

    const classes = useStyles();

    const gotoPage = (page) => {
        history.push(page);
    };

    return (
        <>
            <div className={classes.narbarPosition}>
                <Container maxWidth="lg" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <Grid container spacing={2} alignItems="center" justifyContent={isLargeScreen ? "flex-end" : "center"}>
                        <Grid item xs={5} md={3}>
                            <div className={classes.creditAlign}>
                                <div className="relative" onClick={() => { gotoPage("/deposit/form/decimal") }}>
                                    <div className={classes.coinPosition}>
                                        <img className={classes.coinSize} src="/images/icon/coin.webp" alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                    </div>
                                    <div>
                                        {
                                            homeData !== null ? currencyFormat(homeData.balance, 2) : 0.00
                                        }
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={5} md={3}>
                            {
                                homeData !== null && (
                                    homeData.wallets !== null && (
                                        <div className={classes.creditAlign}>
                                            <div className="relative" onClick={() => { gotoPage("/activity") }}>
                                                <div className={classes.coinPosition}>
                                                    <img className={classes.coinSize} src={homeData.wallets[0].image} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                                </div>
                                                <div>
                                                    {
                                                        currencyFormat(homeData.wallets[0].balance, 2)
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )
                            }
                        </Grid>
                        <Grid item xs={1} md={1}>
                            <div className="left">
                                <ReloadBlance></ReloadBlance>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </>
    );
}