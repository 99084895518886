import axios from 'axios';
import serviceConfig from "./serviceConfig";
import { getToken } from "./../auth/utils";
import { checkStatusCode } from "./services";

const svConfig = { ...serviceConfig };

const loadActivityAll = async () => {
  let config = {
    method: svConfig.method_get,
    url: `/customer/activity/list`,
    headers: {
      "Authorization": `${svConfig.headerType} ${getToken()}`,
      "Content-Type": svConfig.contentType
    },
    data: {}
  };

  const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
  const { code, result } = dataPromise;

  if (checkStatusCode(code)) {
    if (checkStatusCode(code)) {
      if (code === 0) {
        return {
            result: result === null ? [] : result,
            code
        };
      }
      else {
        return [];
      }
    }
  }
}

const confirmActivityCoupon = async (activity_id, codeCoupon) => {
    console.log(activity_id, codeCoupon)
    let myJson = new URLSearchParams({
        "activity_id": activity_id,
        "code": codeCoupon
    });

    let config = {
        method: svConfig.method_post,
        url: "/customer/activity/coupon/apply",
        headers: {
            "Authorization": `${svConfig.headerType} ${getToken()}`,
            "Content-Type": svConfig.contentType
        },
        data: myJson
    };

    const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
    const { code, result } = dataPromise;

    if (checkStatusCode(code)) {
        return {
            result: result,
            code: code
        };
    }
}

export {
    loadActivityAll,
    confirmActivityCoupon
};