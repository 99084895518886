var moment = require('moment-timezone');

const timeZone = (val, type) => {
    if(type === "time") {
        return moment.tz(val, "YYYY-MM-DDTHH:mm:ssZ", 'Asia/Bangkok').format('HH:mm');
    }
    else if(type === "date") {
        return moment.tz(val, "YYYY-MM-DDTHH:mm:ssZ", 'Asia/Bangkok').format('YYYY-MM-DD');
    }
    else if(type === "noFormat") {
        return moment.tz(val, "YYYY-MM-DDTHH:mm:ssZ", 'Asia/Bangkok');
    }
    else if(type === "year") {
        return moment.tz(val, "YYYY-MM-DDTHH:mm:ssZ", 'Asia/Bangkok').format('YYYY');
    }
    else if(type === "timestamp") {
        return moment.tz(val, "YYYY-MM-DDTHH:mm:ssZ", 'Asia/Bangkok').unix();
    }
    else {
        console.log("fdsafdsa")
        return moment.tz(val, "YYYY-MM-DDTHH:mm:ssZ", (localStorage.getItem('timeZone') === null || localStorage.getItem('timeZone') === undefined) ? 'Asia/Bangkok' : localStorage.getItem('timeZone')).format('YYYY-MM-DD HH:mm:ss');
    }
};

export default timeZone;