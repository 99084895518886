import { useEffect, useRef, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
// import Alert from '@material-ui/lab/Alert';

// auth
import { isUserLoggedIn } from "./../../../../../@core/auth/utils";

// @utility
import alertText from "./../../../../../@utility/app/alertText";
import validateInput from "./../../../../../@utility/app/validateInput";

// Hooks
// import useParameterUrl from "./../../../../../@utility/hooks/useParameterUrl";

// Services
import { loginLine } from "./../../../../../@core/auth/jwt/jwtService";
import { checkUsername } from "./../../../../../@core/auth/jwt/jwtService";
// import { loadUrlLineLogin } from "./../../../../../@core/services/appService";

//  CSS
// import styles from "./../../assets/css/Style.module.css";
import formStyles from "./../../assets/css/Input.module.css";
import buttonStyles from "./../../assets/css/Button.module.css";

// Icon
import AccountCircle from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';
import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';
import PhoneIcon from '@material-ui/icons/Phone';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

// Component
import LoadContent from "./../LoadContent";
import SnackbarSet from "./../SnackbarSet";

export default function Register01App(props) {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const childRef = useRef();
    let snackbarJson = {};
    const dispatch = useDispatch();
    const [processing, setProcessing] = useState(false);
    // const parsed = useParameterUrl();
    // let { code, affiliate, refer, know_web, ref } = parsed;
    // const [urlLine, setUrlLine] = useState(null);
    // let urlCurrent = `${window.location.protocol}//${window.location.host}?login=true&affiliate=${affiliate === undefined ? null : affiliate}&refer=${refer === undefined ? null : refer}&know_web=${know_web === undefined ? null : know_web}`;

    const onSubmit = (data) => {
        if (data.password === data.passwordConfrim) {
            let myJson = {
                "username": isLine() ? props.register.line_data.username : data.username,
                // "username": data.phone,
                "password": isLine() ? "" : data.password,
                "phone": data.phone,
                "refer": localStorage.getItem("refer") === null ? "" : localStorage.getItem("refer"),
                "affiliate": localStorage.getItem("affiliate") === null ? "" : localStorage.getItem("affiliate")
            }

            // ใช้ตัวอ้างอิงการตลาด
            if (localStorage.getItem("market") !== null && localStorage.getItem("market") !== "Null" && localStorage.getItem("market") !== "null" && localStorage.getItem("market") !== "") {
                myJson.refer = localStorage.getItem("market")
            }
            

            // ตรวจสอบว่าสมัครผ่านไลน์มั้ย
            if (!isLine()) {
                setProcessing(true); 
                // เช็ค username ซ้ำกัน

                checkUsername(myJson).then((data) => {
                    setProcessing(false); 
                    const statusCode = data;
                    if (statusCode === 200 || statusCode === 0) {
                        props.update(myJson);
                        props.onStep(2); // เลือก step ที่กรอกบัญชีธนาคาร
                    }
                    else {
                        let dataText = alertText(statusCode);

                        dispatch(props.setDataAlert({
                            type: dataText.statusAlert,
                            title: dataText.titleAlert,
                            text: dataText.textAlert,
                            action: true,
                            redirect: "",
                            show: true,
                            buttonText: "ตกลง"
                        }));

                        // snackbarJson = {
                        //     statusShow: true,
                        //     statusAlert: "warning",
                        //     titleAlert: "แจ้งเตือน",
                        //     textAlert: "ชื่อผู้ใช้งานซ้ำในระบบ กรุณาลองชื่อผู้ใช้งานอื่น"
                        // };

                        // props.snackbar(snackbarJson);
                    }
                })
            }
            else {
                // สมัครผ่านไลน์
                props.update(myJson);
                props.onStep(2); // เลือก step ที่กรอกบัญชีธนาคาร
            }
        }
        else {
            dispatch(props.setDataAlert({
                type: "warning",
                title: "แจ้งเตือน",
                text: "รหัสผ่านไม่ตรงกัน กรุณากรอกข้อมูลใหม่อีกครั้ง",
                action: true,
                redirect: "",
                show: true,
                buttonText: "ตกลง"
            }));

            // snackbarJson = {
            //     statusShow: true,
            //     statusAlert: "warning",
            //     titleAlert: "แจ้งเตือน",
            //     textAlert: "รหัสผ่านไม่ตรงกัน กรุณากรอกข้อมูลใหม่อีกครั้ง"
            // };

            // props.snackbar(snackbarJson);
        }
    };

    const renderErrorUsername = () => {
        if (errors.username?.type === "minLength") {
            return (
                <div className="validateError">
                    กรุณาระบุยูสเซอร์เนมมากกว่า 4 ตัวอักษร
                </div>
            );
        }
        else if (errors.username?.type === "pattern") {
            return (
                <div className="validateError">
                    ยูสเซอร์เนมที่คุณระบุมีอักขระพิเศษ
                </div>
            );
        }

        return (
            <div className="validateInfo">
                ไม่ใช้อักขระพิเศษในยูสเซอร์เนม
            </div>
        );
    };

    const renderErrorPassword = () => {
        if (errors.password?.type === "minLength") {
            return (
                <div className="validateError">
                    กรุณาระบุรหัสผ่านมากกว่า 4 ตัวอักษร
                </div>
            );
        }
        else if (errors.password?.type === "pattern") {
            return (
                <div className="validateError">
                    รหัสผ่านที่คุณระบุมีอักขระพิเศษ
                </div>
            );
        }

        return (
            <div className="validateInfo">
                รหัสผ่านมากกว่า 4 ตัวอักษร
            </div>
        );
    };

    const renderErrorPasswordConfirm = () => {
        if (errors.password?.type === "minLength") {
            return (
                <div className="validateError">
                    กรุณาระบุรหัสผ่านมากกว่า 4 ตัวอักษร
                </div>
            );
        }
        else if (errors.password?.type === "pattern") {
            return (
                <div className="validateError">
                    รหัสผ่านที่คุณระบุมีอักขระพิเศษ
                </div>
            );
        }

        return (
            <div className="validateInfo">
                รหัสผ่านมากกว่า 4 ตัวอักษร
            </div>
        );
    };

    const renderErrorPhone = () => {
        if (errors.phone?.type === "minLength") {
            return (
                <div className="validateError">
                    กรุณาระบุเบอร์โทรศัพท์ให้ถูกต้อง
                </div>
            );
        }
        else if (errors.phone?.type === "pattern") {
            return (
                <div className="validateError">
                    เบอร์โทรศัพท์ที่คุณระบุมีอักขระพิเศษ
                </div>
            );
        }

        return (
            <div className="validateInfo">
                ไม่ต้องใส่ขีด (-) ในเบอร์โทรศัพท์
            </div>
        );
    };

    // ตรวจสอบว่ามีการเข้าผ่านไลมั้ย
    const isLine = () => {
        return (props.register.line_data === undefined) ? false : true
    };

    // const gotoLinkLine = () => {
    //     loadUrlLineLogin(`${window.location.protocol}//${window.location.host}?login=true`).then((data) => {
    //         const statusCode = data.code;
    //         if (statusCode === 200 || statusCode === 0) {
    //             window.location.href = data.result.url
    //         }
    //         else {
    //             dispatch(props.setDataAlert({
    //                 type: "warning",
    //                 title: "แจ้งเตือน",
    //                 text: "ไม่สามารถเข้าสู่ระบบผ่านไลน์ได้ กรุณาลองใหม่อีกครั้ง หรือติดต่อผู้ดูแลระบบ !",
    //                 action: true,
    //                 redirect: "",
    //                 show: true,
    //                 buttonText: "ตกลง"
    //             }));

    //             // snackbarJson = {
    //             //     statusShow: true,
    //             //     statusAlert: "warning",
    //             //     titleAlert: "แจ้งเตือน",
    //             //     textAlert: "ไม่สามารถเข้าสู่ระบบผ่านไลน์ได้ กรุณาลองใหม่อีกครั้ง หรือติดต่อผู้ดูแลระบบ !"
    //             // };

    //             // props.alert(snackbarJson);
    //         }
    //     })
    // };

    const authLine = (code) => {
        let myJson = {
            "code": code,
            "url": `${window.location.protocol}//${window.location.host}?register=true`
        }

        loginLine(myJson).then((data) => {
            const statusCode = data.code;
            const result = data.result;

            if (statusCode === 200 || statusCode === 0) {
                if (result?.username === undefined || result === null || result === undefined) {
                    // มีบัญชีอยู่ในระบบ
                    // reloadHomeData();
                    snackbarJson = { statusShow: true, statusAlert: "success", titleAlert: "แจ้งเตือน", textAlert: "เข้าสู่ระบบสำเร็จ !" }
                    props.alert(snackbarJson);
                    props.onProcess(false);
                    props.onShow(false);
                } else {
                    // ยังไม่มีบัญชีในระบบ
                    props.onData(result);
                    props.switchChanel('register')
                    props.onProcess(false);
                }

            } else {
                // เกิดข้อผิดพลาดในการเข้าระบบไลน์
                snackbarJson = { statusShow: true, statusAlert: "warning", titleAlert: "แจ้งเตือน", textAlert: "เกิดข้อผิดพลาดในเข้าระบบไลน์ !" }
                props.alert(snackbarJson)
                props.onProcess(false)
            }
        });
    };

    useEffect(() => {
        if (isLine()) {
            let myJson = {
                "username": props.register.line_data.username,
                "password": "",
                "refer": localStorage.getItem("refer") === null ? "" : localStorage.getItem("refer"),
                "affiliate": localStorage.getItem("affiliate") === null ? "" : localStorage.getItem("affiliate")
            }

            props.update(myJson);
            props.onStep(2);
        }
    }, [isLine]);

    useEffect(() => {
        if (!isUserLoggedIn()) {
            // เช็คการเข้าสู่ระบบผ่านไลน์
            if (props.codeLine !== undefined && props.codeLine !== "") {
                props.onProcess(true);
                authLine(props.codeLine);
            }
        }
    }, [props.codeLine]);

    return (
        <>
            <SnackbarSet ref={childRef} />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                    {
                        !isLine() ?
                            <>
                                <Grid item xs={12}>
                                    <br />
                                    <div className={formStyles.inputPaddingRegisterTop}>
                                        <div className={formStyles.inputTextLabel}>
                                            ยูสเซอร์เนม*
                                        </div>
                                        <Grid container alignItems="center" spacing={1} className={[formStyles.inputStyles, "center"].join(" ")} >
                                            <Grid item xs={2}>
                                                <AccountCircle className={formStyles.iconSize} />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <input type="text" defaultValue={props.register.username} className={[formStyles.inputStyles, "kanit"].join(" ")} placeholder="ยูสเซอร์เนม" {...register("username", { required: true, minLength: 4, maxLength: 20, pattern: /^[A-Za-z0-9]+$/i })} onKeyPress={(event) => validateInput(event, "textNumber")} maxLength="20" required />
                                            </Grid>
                                        </Grid>
                                        {
                                            renderErrorUsername()
                                        }
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={formStyles.inputPaddingRegisterTop}>
                                        <div className={formStyles.inputTextLabel}>
                                            รหัสผ่าน*
                                        </div>
                                        <Grid container alignItems="center" spacing={1} className={[formStyles.inputStyles, "center"].join(" ")} >
                                            <Grid item xs={2}>
                                                <EnhancedEncryptionIcon className={formStyles.iconSize} />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <input type="password" defaultValue={props.register.password} className={[formStyles.inputStyles, "kanit", formStyles.inputWidth].join(" ")} maxLength="20" placeholder="รหัสผ่าน"  {...register("password", { required: true, maxLength: 20, minLength: 4, pattern: /^[A-Za-z0-9]+$/i })} onKeyPress={(event) => validateInput(event, "textNumber")} required />
                                            </Grid>
                                        </Grid>
                                        {
                                            renderErrorPassword()
                                        }
                                    </div>
                                    <div className={formStyles.inputPaddingRegisterTop}>
                                        <div className={formStyles.inputTextLabel}>
                                            ยืนยันรหัสผ่านอีกครั้ง*
                                        </div>
                                        <Grid container alignItems="center" spacing={1} className={[formStyles.inputStyles, "center"].join(" ")} >
                                            <Grid item xs={2}>
                                                <LockIcon className={formStyles.iconSize} />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <input type="password" defaultValue={props.register.password} className={[formStyles.inputStyles, "kanit", formStyles.inputWidth].join(" ")} maxLength="20" placeholder="รหัสผ่าน"  {...register("passwordConfrim", { required: true, maxLength: 20, minLength: 4, pattern: /^[A-Za-z0-9]+$/i })} onKeyPress={(event) => validateInput(event, "textNumber")} required />
                                            </Grid>
                                        </Grid>
                                        {
                                            renderErrorPasswordConfirm()
                                        }
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <br />
                                    <div className={formStyles.inputPaddingRegisterTop}>
                                        <div className={formStyles.inputTextLabel}>
                                            เบอร์โทรศัพท์*
                                        </div>
                                        <Grid container alignItems="center" spacing={1} className={[formStyles.inputStyles, "center"].join(" ")} >
                                            <Grid item xs={2}>
                                                <PhoneIcon className={formStyles.iconSize} />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <input type="tel" defaultValue={props.register.phone} className={[formStyles.inputStyles, "kanit"].join(" ")} placeholder="เบอร์โทรศัพท์" {...register("phone", { required: true, maxLength: 10, pattern: /^[0-9]+$/i })} onKeyPress={(event) => validateInput(event, "number")} maxLength="10" required />
                                            </Grid>
                                        </Grid>
                                        {
                                            renderErrorPhone()
                                        }
                                    </div>
                                </Grid>
                            </>
                            :
                            <>
                                <Grid item xs={12}>
                                    <br />
                                    <div className={formStyles.inputPaddingRegisterTop}>
                                        <div className={formStyles.inputTextLabel}>
                                            ยูสเซอร์เนม*
                                        </div>
                                        <Grid container alignItems="center" spacing={1} className={[formStyles.inputStyles, "center"].join(" ")} >
                                            <Grid item xs={2}>
                                                <AccountCircle className={formStyles.iconSize} />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <input type="text" readOnly="readOnly" defaultValue={props.register.line_data.username} className={[formStyles.inputStyles, "kanit"].join(" ")} placeholder="ยูสเซอร์เนม" />
                                            </Grid>
                                        </Grid>
                                        {
                                            renderErrorUsername()
                                        }
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={formStyles.inputPaddingRegisterTop}>
                                        <div className={formStyles.inputTextLabel}>
                                            รหัสผ่าน*
                                        </div>
                                        <Grid container alignItems="center" spacing={1} className={[formStyles.inputStyles, "center"].join(" ")} >
                                            <Grid item xs={2}>
                                                <EnhancedEncryptionIcon className={formStyles.iconSize} />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <input type="password" readOnly="readOnly" defaultValue={props.register.line_data.username} className={[formStyles.inputStyles, "kanit", formStyles.inputWidth].join(" ")} placeholder="รหัสผ่าน" />
                                            </Grid>
                                        </Grid>
                                        {
                                            renderErrorPassword()
                                        }
                                    </div>
                                    <div className={formStyles.inputPaddingRegisterTop}>
                                        <div className={formStyles.inputTextLabel}>
                                            ยืนยันรหัสผ่านอีกครั้ง*
                                        </div>
                                        <Grid container alignItems="center" spacing={1} className={[formStyles.inputStyles, "center"].join(" ")} >
                                            <Grid item xs={2}>
                                                <LockIcon className={formStyles.iconSize} />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <input type="password" readOnly="readOnly" defaultValue={props.register.line_data.username} className={[formStyles.inputStyles, "kanit", formStyles.inputWidth].join(" ")} placeholder="รหัสผ่าน" />
                                            </Grid>
                                        </Grid>
                                        {
                                            renderErrorPasswordConfirm()
                                        }
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={formStyles.inputPaddingRegisterTop}>
                                        <div className={formStyles.inputTextLabel}>
                                            เบอร์โทรศัพท์*
                                        </div>
                                        <Grid container alignItems="center" spacing={1} className={[formStyles.inputStyles, "center"].join(" ")} >
                                            <Grid item xs={2}>
                                                <PhoneIcon className={formStyles.iconSize} />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <input type="tel" defaultValue={props.register.phone} className={[formStyles.inputStyles, "kanit"].join(" ")} placeholder="เบอร์โทรศัพท์" {...register("phone", { required: true, maxLength: 10, pattern: /^[0-9]+$/i })} onKeyPress={(event) => validateInput(event, "number")} maxLength="10" required />
                                            </Grid>
                                        </Grid>
                                        {
                                            renderErrorPhone()
                                        }
                                    </div>
                                </Grid>
                            </>
                    }
                </Grid>

                {
                    processing === false ? 
                        <div className={[formStyles.inputPaddingTop, "center"].join(" ")} style={{ marginTop: "30px" }}>
                            <button type="submit" className={[buttonStyles.btnRedirect, buttonStyles.btnRedirectComponent].join(" ")} style={{ display: "inline-block" }}>
                                <Grid container alignItems="center" justifyContent="center" spacing={2}>
                                    <Grid item>
                                        ถัดไป
                                    </Grid>
                                    <Grid item>
                                        <ArrowForwardIcon className={[buttonStyles.iconSize, buttonStyles.iconSizeMarginTop].join(" ")}></ArrowForwardIcon>
                                    </Grid>
                                </Grid>
                            </button>
                        </div>
                        :
                        <div className="center">
                            <LoadContent></LoadContent>
                            <div style={{ paddingTop: "12px", color: "white" }}>
                                ระบบกำลังตรวจสอบบัญชี "<span style={{ color: "#FFF500" }}>ยูสเซอร์เนม</span>"
                            </div>
                        </div>
                }
            </form>
            {/* 
            <div className={formStyles.borderSocial}>
                <div className={formStyles.orPositionCenter}>
                    <div className={formStyles.orText}>
                        หรือ
                    </div>
                </div>
            </div>

            <div className={[formStyles.inputPaddingTop, "center"].join(" ")}>
                <div className={[buttonStyles.btnLogin, buttonStyles.btnContactSize].join(" ")} onClick={gotoLinkLine}>
                    <Grid container alignItems="center" justifyContent="center" spacing={4} style={{ height: "100%" }}>
                        <Grid item>
                            <img src="/images/icon/line_white.webp" className={[styles.iconSizeSub, formStyles.iconSizeSub].join(" ")} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                        </Grid>
                        <Grid item>
                            เข้าสู่ระบบด้วยไลน์
                        </Grid>
                    </Grid>
                </div>
            </div> */}
        </>
    );
}