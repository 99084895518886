import { getCustomerToken } from "./jwtService";

let customerToken = getCustomerToken();
if (customerToken === null) {
  customerToken = localStorage.getItem("generalToken");
}

const data = {
  loginEndpoint: "/auth/login-username",
  registerEndpoint: "/auth/register-option",
  refreshEndpoint: "/app/refresh-token",
  logoutEndpoint: "/auth/logout",
  loginLine: "/auth/login-line",
  checkUsername: "/auth/check-username",
  checkBankAccount: "/auth/check-bank-account",
  checkAccount: "/auth/check-account",
  refreshToken: "/customer/generate/refresh-token",
  tokenName: "customerToken",
  headerType: 'Bearer',
  contentType: "application/x-www-form-urlencoded",
  method_get: "get",
  method_post: "post",
  method_put: "put"
};

export default data;
