import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

// Component
import Register01App from "./Register-01";
import Register02App from "./Register-02";
import Register03App from "./Register-03";
import SnackbarSet from "./../SnackbarSet";

// Services
import { register as registerApp } from "./../../../../../@core/auth/jwt/jwtService";
import { loadHomeData } from "./../../../../../@core/services/homeService";

// Redux
import { setHomeData } from "./../../../../../@core/redux/actions/home/index";

// @utility
import alertText from "./../../../../../@utility/app/alertText";

export default function RegisterApp(props) {
    const dispatch = useDispatch();
    const childRef = useRef();
    const [register, setRegister] = useState({
        "username": "",
        "password": "",
        "phone": "",
        "affiliate": "",
        "refer": "",
        "know_web": "",
        "bank": "",
        "bank_account": "",
        "bank_account_name": "",
        "tm_phone": "",
        "line_data": props.data,
        "allusion": localStorage.getItem("allusion")
    });

    const snackbar = (da) => {
        childRef.current.snackbar(da);
    }

    const updateRegister = (da) => {
        setRegister({ ...register, ...da })
    }

    const reloadHomeData = () => {
        loadHomeData().then((data) => {
            if (data.code === 0 || data.code === 200) {
                // if (data.result.banks === null) {
                //     openBox();
                // }

                dispatch(setHomeData(data.result));
            }
        });
    }

    const createRegister = (myJson) => {
        props.onProcess(true)
        registerApp(myJson).then((data) => {
            props.onProcess(false)
            const statusCode = data;
            if (statusCode === 200 || statusCode === 0) {
                reloadHomeData();
                dispatch(props.setDataAlert({
                    type: "success",
                    title: "แจ้งเตือน",
                    text: "สมัครสมาชิกสำเร็จ !",
                    action: true,
                    redirect: "",
                    show: true,
                    buttonText: "ตกลง"
                }));

                // snackbar({
                //     statusShow: true,
                //     statusAlert: "success",
                //     titleAlert: "แจ้งเตือน",
                //     textAlert: "สมัครสมาชิกสำเร็จ !"
                // })
                
                props.onShow(false)
                localStorage.removeItem("refer");
                localStorage.removeItem("affiliate");
                localStorage.removeItem("allusion");

            }
            else {
                snackbar(alertText(statusCode));
            }
        });
    }

    return (
        <>
            <SnackbarSet ref={childRef} />
            {!props.process ?
                <>
                    {
                        props.step === 1 ? <Register01App update={updateRegister} register={register} snackbar={snackbar} onStep={props.onStep} setDataAlert={props.setDataAlert} /> : null
                    }
                    {
                        props.step === 2 ? <Register02App update={updateRegister} register={register} snackbar={snackbar} onStep={props.onStep} setDataAlert={props.setDataAlert} /> : null
                    }
                    {
                        props.step === 3 ? <Register03App update={updateRegister} save={createRegister} register={register} snackbar={snackbar} onStep={props.onStep} setDataAlert={props.setDataAlert} /> : null
                    }
                </> : null
            }
        </>
    );
}