import { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// Hooks
// import useParameterUrl from "../../../../../@utility/hooks/useParameterUrl";

// @utility
import validateInput from "../../../../../@utility/app/validateInput";

// Services
import { checkBankAccount } from "../../../../../@core/auth/jwt/jwtService";

// Component
import LoadContent from "./../LoadContent";

//  CSS
import formStyles from "./../../assets/css/Input.module.css";
import buttonStyles from "./../../assets/css/Button.module.css";
import modalStyles from "./../../assets/css/Modal.module.css";

// Icon
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PhoneIcon from '@material-ui/icons/Phone';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import PersonIcon from '@material-ui/icons/Person';

export default function Register02App(props) {
    const dispatch = useDispatch();
    const [bankList, setBankList] = useState([]);
    const [bankTM, setBankTM] = useState(props.register.tm_phone !== "" && props.register.tm_phone !== undefined ? true : false);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [processing, setProcessing] = useState(false);
    // const parsed = useParameterUrl();

    const dataSetting = useSelector(state => state.reduxAppMenu.menu_items);

    const onSubmit = (data) => {
        let myJson = {
            "bank": data.bank,
            "bank_account": data.bank_account,
            "bank_account_name": data.bank_account_name,
            "tm_phone": data.tm_phone,
            "phone":  isLine() ? data.phone : props.register.phone
        }

        // ตรวจสอบว่า select bank มีหรือไม่
        if (data.bank != 0) {
            setProcessing(true); 
            
            // เช็คบัญชี ซ้ำกันมั้ย
            checkBankAccount(myJson).then((data) => {
                setProcessing(false);
                const statusCode = data;
                if (statusCode === 200 || statusCode === 0) {
                    props.update(myJson);
                    props.onStep(3); // เลือก step ที่กรอกบัญชีธนาคาร
                } 
                else {
                    dispatch(props.setDataAlert({
                        type: "warning",
                        title: "แจ้งเตือน",
                        text: "บัญชีธนาคารซ้ำในระบบ กรุณาลองบัญชีธนาคารอื่น",
                        action: true,
                        redirect: "",
                        show: true,
                        buttonText: "ตกลง"
                    }));
                }
            })
        }
        else {
            let snackbarJson = {
                statusShow: true,
                statusAlert: "warning",
                titleAlert: "แจ้งเตือน",
                textAlert: "กรุณาเลือกบัญชีธนาคาร"
            };

            props.snackbar(snackbarJson);
        }
    };

    const renderErrorBankAccount = () => {
        if (errors.bank_account?.type === "pattern") {
            return (
                <div className="validateError">
                    เลขบัญชีญธนาคารที่คุณระบุมีอักขระพิเศษ
                </div>
            );
        } 
        else if (errors.bank_account?.type === "minLength") {
            return (
                <div className="validateError">
                    กรอกเลขบัญชีธนาคารให้ถูกต้อง
                </div>
            );
        }

        return (
            <div className="validateInfo">
                ไม่ใช้อักขระพิเศษในเลขบัญชีญธนาคาร
            </div>
        );
    };

    const renderErrorBankAccountName = () => {
        if (errors.bank_account_name?.type === "minLength") {
            return (
                <div className="validateError">
                    กรุณากรอกชื่อบัญชีธนาคาร
                </div>
            );
        }
        else if (errors.bank_account_name?.type === "pattern") {
            return (
                <div className="validateError">
                    ชื่อบัญชีที่คุณระบุมีอักขระพิเศษ
                </div>
            );
        }

        return (
            <div className="validateInfo">
                โปรดตรวจสอบชื่อ เเละหมายเลขบัญชี หากไม่ถูกต้องจะไม่สามารถฝากถอนได้
            </div>
        );
    };

    const renderErrorPhone = () => {
        if (errors.tm_phone?.type === "minLength" || errors.tm_phone?.type === "maxLength") {
            return ( 
                <div className="validateError">
                    กรุณาระบุเบอร์โทรศัพท์ให้ถูกต้อง
                </div>
            );
        }
        else if (errors.tm_phone?.type === "pattern") {
            return (
                <div className="validateError">
                    เบอร์โทรศัพท์ที่คุณระบุมีอักขระพิเศษ
                </div>
            );
        }

        return (
            <div className="validateInfo">
                ไม่ต้องใส่ขีด (-) ในเบอร์ ทรูมันนี่วอลเล็ท
            </div>
        );
    };

    // ตรวจสอบว่ามีการเข้าผ่านไลมั้ย
    const isLine = () => {
        return (props.register.line_data === undefined) ? false : true
    };

    useEffect(() => {
        setBankList(dataSetting.agent_setting_bank);
    }, [dataSetting.agent_setting_bank]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
                <Grid item xs={12}>
                    <br />
                    <div className={modalStyles.boxTitle}>
                        เพิ่มบัญชีเติมเงิน
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={formStyles.inputPaddingRegisterTop}>
                        <br />
                        <div className={formStyles.inputTextLabel}>
                            ธนาคาร*
                        </div>
                        <Grid container alignItems="center" className={[formStyles.selectStyles, "kanit"].join(" ")} >
                            <Grid item style={{ width: "100%" }}>
                                <select key="bank_select"  defaultValue={props.register.bank} className={[formStyles.selectStyles, "kanit"].join(" ")} {...register("bank", { required: true })} style={{ width: "100%" }}>
                                    <option key="bank_0" value="">เลือกธนาคาร</option>
                                    {
                                        bankList.map(item => {
                                            return (
                                                item.bank_switfcode === props.register.bank ?
                                                    <option selected key={item.bank_switfcode} value={item.bank_switfcode}>{item.bank_name}</option>
                                                    :
                                                    <option key={item.bank_switfcode} value={item.bank_switfcode}>{item.bank_name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </Grid>

                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={formStyles.inputPaddingRegisterTop}>
                        <div className={formStyles.inputTextLabel}>
                            เลขบัญชีธนาคาร*
                        </div>
                        <Grid container alignItems="center" spacing={1} className={[formStyles.inputStyles, "center"].join(" ")} >
                            <Grid item xs={2}>
                                <AccountBalanceIcon className={formStyles.iconSize} />
                            </Grid>
                            <Grid item xs={10}>
                                <input type="text" defaultValue={props.register.bank_account} className={[formStyles.inputStyles, "kanit"].join(" ")} placeholder="กรอกเลขบัญชีธนาคาร" {...register("bank_account", { required: true, maxLength: 13, minLength: 10, pattern: /^[0-9]+$/i })} onKeyPress={(event) => validateInput(event, "number")} maxLength="13" required />
                            </Grid>
                        </Grid>
                        {
                            renderErrorBankAccount()
                        }
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={formStyles.inputPaddingTop}>
                        <div className={formStyles.inputTextLabel}>
                            ชื่อ - นามสกุล*
                        </div>
                        <Grid container alignItems="center" spacing={1} className={[formStyles.inputStyles, "center"].join(" ")} >
                            <Grid item xs={2}>
                                <PersonIcon className={formStyles.iconSize} />
                            </Grid>
                            <Grid item xs={10}>
                                <input type="text" defaultValue={props.register.bank_account_name} className={[formStyles.inputStyles, "kanit"].join(" ")} placeholder="ชื่อ - นามสกุล" {...register("bank_account_name", { required: true, maxLength: 50, pattern: /^[a-zA-Zก-๙ ]+$/i })} onKeyPress={(event) => validateInput(event, "")} maxLength="50" required />
                            </Grid>
                        </Grid>
                        {
                            renderErrorBankAccountName()
                        }
                    </div>
                </Grid>
                {
                    isLine() && (
                        <Grid item xs={12}>
                            <div className={formStyles.inputPaddingTop}>
                                <div className={formStyles.inputTextLabel}>
                                    เบอร์โทรศัพท์*
                                </div>
                                <Grid container alignItems="center" spacing={1} className={[formStyles.inputStyles, "center"].join(" ")} >
                                    <Grid item xs={2}>
                                        <PhoneIcon className={formStyles.iconSize} />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <input type="tel" className={[formStyles.inputStyles, "kanit"].join(" ")} placeholder="เบอร์โทรศัพท์" {...register("phone", { required: true, maxLength: 10, pattern: /^[0-9]+$/i })} onKeyPress={(event) => validateInput(event, "number")} maxLength="10" required />
                                    </Grid>
                                </Grid>

                                <br />
                            </div>
                        </Grid>
                    )
                }
                <Grid item xs={12}>
                    <div className={[formStyles.inputPaddingTop, "center"].join(" ")} style={{ cursor: "pointer" }} onClick={() => setBankTM(!bankTM)}>
                        {
                            !bankTM ?
                                <Grid container alignItems="center" className={[formStyles.inputTM, "kanit"].join(" ")} >
                                    <Grid item xs={2}>
                                        <img className={formStyles.iconSize} src="/images/bank/True.png" alt="ทรูมันนี่วอลเล็ท" />
                                    </Grid>
                                    <Grid item xs={7}>
                                        เพิ่มทรูมันนี่วอลเล็ท
                                    </Grid>
                                    <Grid item xs={3} style={{ color: "#B2CAFF" }}>
                                        ไม่บังคับ
                                    </Grid>
                                </Grid>
                                :
                                <Grid container alignItems="center" className={[formStyles.inputSelectTM, "kanit"].join(" ")} >
                                    <Grid item xs={2}>
                                        <img width="24" height="24" src="/images/bank/True.png" alt="ทรูมันนี่วอลเล็ท" />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <div className="left">
                                            ทรูมันนี่วอลเล็ท
                                        </div>
                                    </Grid>
                                </Grid>
                        }

                    </div>
                </Grid>
                {
                    bankTM ?
                        <Grid item xs={12}>
                            <div className={formStyles.inputPaddingRegisterTop}>
                                <div className={formStyles.inputTextLabel}>
                                    เบอร์โทรศัพท์(ทรูมันนี่วอลเล็ท)*
                                </div>
                                <Grid container alignItems="center" spacing={1} className={[formStyles.inputStyles, "center"].join(" ")} >
                                    <Grid item xs={2}>
                                        <PhoneIcon className={formStyles.iconSize} />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <input type="tel" defaultValue={props.register.tm_phone} className={[formStyles.inputStyles, "kanit"].join(" ")} placeholder="เบอร์โทรศัพท์ ทรูมันนี่วอลเล็ท" {...register("tm_phone", { required: true, maxLength: 10, minLength: 10, pattern: /^[0-9]+$/i })} onKeyPress={(event) => validateInput(event, "number")} maxLength="10" required />
                                    </Grid>
                                </Grid>
                                {
                                    renderErrorPhone()
                                }
                            </div>
                        </Grid>
                        :
                        null
                }
            </Grid>

            {
                processing === false ? 
                    <div className={[formStyles.inputPaddingTop, "center"].join(" ")} style={{ marginTop: "20px" }}>
                        <button type="submit" className={[buttonStyles.btnRedirect, buttonStyles.btnRedirectComponent].join(" ")} style={{ display: "inline-block" }}>
                            <Grid container alignItems="center" justifyContent="center" spacing={2}>
                                <Grid item>
                                    ถัดไป
                                </Grid>
                                <Grid item>
                                    <ArrowForwardIcon className={[buttonStyles.iconSize, buttonStyles.iconSizeMarginTop].join(" ")}></ArrowForwardIcon>
                                </Grid>
                            </Grid>
                        </button>
                    </div>
                    :
                    <div className="center">
                        <LoadContent></LoadContent>
                        <div style={{ paddingTop: "12px", color: "white" }}>
                            ระบบกำลังตรวจสอบรายการ "<span style={{ color: "#FFF500" }}>บัญชีธนาคาร</span>"
                        </div>
                    </div>
            }
        </form>
    );
}