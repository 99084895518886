function checkStatusCode(statusCode) {
    if (statusCode === 403 || statusCode === 402 || statusCode === 401 || statusCode === 400) {
        let market = localStorage.getItem("market")
        localStorage.clear();
        localStorage.setItem("market", market)
        window.location.reload();
    }
    else {
        return true;
    }
}

export {
    checkStatusCode
}