import { useState } from 'react';
import { Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';

// Component
// import LoadContent from "./../LoadContent";

//  CSS
import formStyles from "./../../assets/css/Input.module.css";
import buttonStyles from "./../../assets/css/Button.module.css";
import modalStyles from "./../../assets/css/Modal.module.css";

export default function Register03App(props) {
    const [knowWeb, setKnowWeb] = useState("");

    const { handleSubmit } = useForm();
    // const parsed = useParameterUrl();

    const defaultKnow = [
        { name: "Google", state: false },
        { name: "Facebook", state: false },
        { name: "Youtube", state: false },
        { name: "เพื่อนแนะนำ", state: false },
        { name: "โซเชียลมีเดีย", state: false },
        { name: "พนักงานขาย", state: false },
        { name: "อื่น ๆ", state: false }
    ]
    const [know, setKnow] = useState(defaultKnow)

    const selectCheck = (index) => {
        var _know = defaultKnow
        _know[index].state = true
        setKnow(_know)
        setKnowWeb(_know[index].name);
    }


    const onSubmit = () => {
        props.save({ ...props.register, "know_web": knowWeb });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
                <Grid item xs={12}>
                    <br />
                    <div className={modalStyles.boxTitle}>
                        คุณรู้จักเราจากไหน ?
                    </div>
                    <br />
                </Grid>

                {know.map((key, index) => (
                    <Grid item xs={12} key={index}>
                        <div className={formStyles.inputPaddingTop}>
                            <Grid container alignItems="center" onClick={() => selectCheck(index)} >
                                <Grid item xs={1}></Grid>
                                <Grid item xs={2} >
                                    <div className="center">
                                        {
                                            key.state ?
                                                <div key={index} className={[formStyles.inputCheck, "center"].join(" ")}>
                                                    <div className={[formStyles.inputCheckActive, "kanit"].join(" ")}></div>
                                                </div>
                                                :
                                                <div key={index} className={[formStyles.inputCheck, "center"].join(" ")}></div>
                                        }
                                    </div>
                                </Grid>
                                <Grid item xs={9} className={[formStyles.inputCheckText, "kanit"].join(" ")}>
                                    {key.name}
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                ))}
                <Grid item xs={12}>
                    <div className={[formStyles.inputPaddingTop, "center"].join(" ")} style={{ paddingTop: "20px" }}>
                        <button type="submit" className={buttonStyles.btnConfirm}>
                            ยืนยันข้อมูล
                        </button>
                    </div>
                </Grid>
            </Grid>
        </form>
    );
}