import { useState, useEffect, useRef } from 'react'
import { Grid } from '@material-ui/core'
import ReactHtmlParser from 'react-html-parser'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { LazyLoadImage } from 'react-lazy-load-image-component'

// auth
import { isUserLoggedIn } from './../../../../../../@core/auth/utils'

import modalStyles from './../../../assets/css/Modal.module.css'
import buttonStyles from './../../../assets/css/Button.module.css'

// Component
import CloseButton from './../../../components/CloseButton'

// CSS
import styles from './../../../assets/css/Style.module.css'

export default function ListPromotion({ pageName, promotionData }) {
    const itemsGameHotList = useSelector(
        (state) => state.reduxGame.itemsGameHotList
    )
    const itemsGameJackpotList = useSelector(
        (state) => state.reduxGame.itemsGameJackpotList
    )
    const itemsCasinoList = useSelector(
        (state) => state.reduxGame.itemsCampList
    )
    const homeData = useSelector((state) => state.reduxHome.result)
    const [hearthStatus, setHearthStatus] = useState(null)
    const [checkPromotion, setCheckPromotion] = useState(null)
    const slideRef = useRef()
    const reduxAppCMS = useSelector((state) => state.reduxAppCMS.cms)

    // Promotion Modal
    const [openModal, setOpenModal] = useState(false)
    const [itemList, setItemList] = useState(null)
    const history = useHistory()

    const useStyles = makeStyles((theme) => ({
        cardGameBody: {
            borderRadius: '15px',
            [theme.breakpoints.down('xs')]: {
                borderRadius: '0px',
                padding: '0px 5px 15px 5px',
            },
        },
        gameSize: {
            width: '100%',
            height: '100%',
            borderRadius: '16px',
            cursor: 'pointer',
            [theme.breakpoints.down('xs')]: {
                borderRadius: '10px',
            },
        },
        cardGameMenu: {
            padding: '0px 12px',
        },
        cardGameList: {
            padding: '20px 0px',
            [theme.breakpoints.down('xs')]: {
                padding: '20px 0px 0px 0px',
            },
        },
        menuList: {
            border: '2px solid #F0F1F9',
            borderRadius: '18px',
            fontSize: '18px',
            padding: '4px 24px',
            cursor: 'pointer',
            position: 'relative',
            color: 'white',
            '&:hover': {
                background: 'linear-gradient(90deg, #360092 0%, #A60E97 100%)',
            },
        },
        gameSizePadding: {
            padding: '10px 4px',
            marginBottom: '10px',
            width: '260px',
            height: '100%',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '0px',
            },
            [theme.breakpoints.down('xs')]: {
                padding: '6px 4px',
                width: '220px',
            },
        },
        gameMore: {
            fontSize: '18px',
            color: reduxAppCMS !== null ? reduxAppCMS['bt-danger'] : '#FF0000',
            fontWeight: '600',
            cursor: 'pointer',
            [theme.breakpoints.down('xs')]: {
                fontSize: '15px',
            },
        },
        contentPromotionList: {
            background:
                reduxAppCMS !== null
                    ? reduxAppCMS['bg-modal']
                    : 'linear-gradient(180deg, #270058 0%, #1F0046 100%)',
            borderRadius: '16px',
            padding: '5px',
            marginRight: '10px'
        },
        boxImgSize: {
            width: '100%',
            borderRadius: '16px',
            height: '100%',
        },
        promotionMore: {
            fontSize: '18px',
            color: reduxAppCMS !== null ? reduxAppCMS['bt-danger'] : '#FF0000',
            fontWeight: '600',
            cursor: 'pointer',
            [theme.breakpoints.down('xs')]: {
                fontSize: '15px',
            },
            textDecoration: 'none',
        },
    }))

    const classes = useStyles()

    useEffect(() => {

        if (isUserLoggedIn()) {
            if (homeData !== null) {
                setCheckPromotion(homeData.promotion_status)
            }
        }
    }, [hearthStatus, itemsGameJackpotList, itemsGameHotList, itemsCasinoList])

    const promotionDetail = (data) => {
        setItemList(data)
        setOpenModal(true)
    }

    const closeBox = () => {
        setOpenModal(false)
    }

    const gotoPromotion = () => {
        history.push('/promotion')
    }

    const gotoDeposit = () => {
        history.push("/deposit/home");
    }

    return (
        <>
            <div className={classes.cardGameBody}>
                <div className={classes.cardGameMenu}>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Grid item>
                            <div className={styles.cardOtherListHeadText}>
                                {pageName}
                            </div>
                        </Grid>
                        <Grid item>
                            <div
                                className={classes.gameMore}
                                onClick={gotoPromotion}
                            >
                                เพิ่มเติม
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.cardGameList}>
                    <div
                        className={styles.menuPositionWidthScroll}
                        ref={slideRef}
                    >
                        <div className="flexContent">
                            {
                                promotionData.length > 0 ?
                                    promotionData.map((item) => {
                                        return (
                                            <div className={classes.contentPromotionList} key={item.id}>
                                                <div className={classes.gameSizePadding}>
                                                    <div className="relative">
                                                        <LazyLoadImage
                                                            alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม"
                                                            effect="blur"
                                                            className={classes.gameSize}
                                                            src={item.content_picture}
                                                        ></LazyLoadImage>
                                                        
                                                        <br />
                                                        <br />
                                                        <div className="center">
                                                            <button
                                                                className={
                                                                    buttonStyles.btnConfirm
                                                                }
                                                                onClick={() => { promotionDetail(item) }}
                                                            >
                                                                อ่านรายละเอียด
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
                {openModal && (
                    <>
                        <div
                            className={modalStyles.contentPosition}
                            style={{ zIndex: '10002' }}
                        >
                            <div className={modalStyles.boxBody}>
                                <div className={modalStyles.box}>
                                    <div
                                        className={
                                            modalStyles.closeButton
                                        }
                                        onClick={closeBox}
                                    >
                                        <CloseButton
                                            backPage={'/'}
                                        ></CloseButton>
                                    </div>
                                    <div
                                        className={
                                            modalStyles.boxContentWidthBig
                                        }
                                        style={{ color: 'white' }}
                                    >
                                        <div
                                            className={
                                                modalStyles.boxContentPadding
                                            }
                                        >
                                            <div
                                                className={
                                                    modalStyles.boxContentScroll
                                                }
                                            >
                                                <div
                                                    className={[
                                                        styles.boxContentPromotionTitle,
                                                        styles.center,
                                                    ].join(' ')}
                                                    style={{
                                                        marginTop:
                                                            '0px',
                                                    }}
                                                >
                                                    รายละเอียด
                                                </div>
                                                <div
                                                    className={
                                                        classes.borderContent
                                                    }
                                                >
                                                    {itemList !==
                                                        null &&
                                                        ReactHtmlParser(
                                                            itemList.content_text
                                                        )}
                                                </div>
                                                <br />
                                                <br />
                                            </div>

                                            <div className="center">
                                                <button
                                                    className={
                                                        buttonStyles.btnConfirm
                                                    }
                                                    onClick={() => {
                                                        gotoDeposit()
                                                    }}
                                                >
                                                    รับโปรโมชั่น
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className={modalStyles.fixedBackdrop}
                            style={{ zIndex: '10001' }}
                        ></div>
                    </>
                )}
            </div>
        </>
    )
}
