import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// CSS
import modalStyles from "./../../../../assets/css/Modal.module.css";

// Component
import FormDeposit from './FormDeposit';
import FormAuto from './FormAuto';
import SnackbarSet from "./../../../../components/SnackbarSet";
import BackButton from "./../../../../components/BackButton";

const DepositFormApp = ({ depositItems }) => {
    const childRef = useRef();
    const [typePage, setTypePage] = useState("");
    const [bankList, setBankList] = useState(null);
    const { type } = useParams();
    const history = useHistory();
    const [selectBank, setSelectBank] = useState(0);
    const dataBankCustomerList = useSelector(state => state.reduxDeposit.dataBank);

    useEffect(() => {
        if(dataBankCustomerList !== null) {
            let dataRes = dataBankCustomerList;
            let dataFilter = [];

            if(type === "truemoney") {
                dataFilter = dataRes.filter((item) => (item.swift_code === "TRUEMONEY"));
            }
            else {
                dataFilter = dataRes.filter((item) => (item.swift_code !== "TRUEMONEY"));
            }

            if(dataFilter.length > 0) {
                setBankList(dataFilter);
                setSelectBank(dataFilter[0].id);
            }
            else {
                setBankList([]);
                setSelectBank(0);
            }
        }
    }, [dataBankCustomerList]);

    useEffect(() => {
        setTypePage(type);
    }, [type]);

    return (
        <>
            <SnackbarSet ref={childRef} />
            <div className={modalStyles.backButtonFull} onClick={() => { history.push("/deposit/home") }}>
                {
                    depositItems !== null && (
                        depositItems.mode !== "pay" && (
                            (depositItems?.channel?.automatic !== false || depositItems?.channel?.truemoney !== false) && (
                                <BackButton></BackButton>
                            )
                        )
                    )
                }
            </div>
            <div id="DepositApp">
                {
                    (type === "automatic" || type === "truemoney") ?
                    <FormAuto typePage={typePage} bankList={bankList} depositItems={depositItems} selectBank={selectBank}></FormAuto>
                    :
                    <FormDeposit typePage={typePage} bankList={bankList} depositItems={depositItems} selectBank={selectBank}></FormDeposit>
                }
            </div>
        </>
    );
}

export default DepositFormApp;