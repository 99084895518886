import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

// Redux
import { setSetting } from './../@core/redux/actions/app/menuSetting';
import { setStatusDropdown } from "./../@core/redux/actions/app/dropdrown";

// Check token
import { isToken } from "./../@core/auth/utils";

// Services
import { loadSetting } from './../@core/services/appService';

// Layout
import Layouts from "./app-mode/Layouts";

const ViewsModule = ({ loadRoutes }) => {
  const dispatch = useDispatch();
  const statusDropdown = useSelector(state => state.reduxAppDropdown.status);

  const clickFullScreen = () => {
    if (statusDropdown === true) {
      dispatch(setStatusDropdown(false));
    }
  };

  useEffect(() => {
    if(loadRoutes) {
      if (isToken()) {
        loadSetting().then((data) => {
          dispatch(setSetting(data.result));
        });
      }
    }
  }, [dispatch, loadRoutes]);

  return <>

    <div style={{ display: "block" }} onClick={() => { clickFullScreen() }}>
      <Router>
        <Layouts loadRoutes={loadRoutes}></Layouts>
      </Router>
    </div>
  </>;
}

export default ViewsModule;