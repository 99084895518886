import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import { useDispatch, useSelector } from 'react-redux';

// Check Auth
import { isUserLoggedIn } from "./../../../../@core/auth/utils";

// @Utility
import filterImages from "./../../../../@utility/app/filterImages";

// Redux
import { openGetCredit, openInviteFriend } from "./../../../../@core/redux/actions/affiliate/index";
import { setHomeMenuType } from "./../../../../@core/redux/actions/home/index";
import { setDataAlert } from "./../../../../@core/redux/actions/app/alert";

// CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function BrannerSlide({ isLargeScreen }) {
    const { path } = useRouteMatch();
    const dataSetting = useSelector(state => state.reduxAppMenu.menu_items);
    const imagesProcessing = useSelector(state => state.reduxAppProcessing.images);
    const reduxAppScrollPosition = useSelector(state => state.reduxAppScrollPosition.top);
    const [pathName, setPathName] = useState("/");
    const [dataList, setDataList] = useState(null);
    const history = useHistory();
    const dispatch = useDispatch();

    // const dataList = [
    //     {
    //         "id": 1,
    //         "imgDesktop": "/images/branner/desktop/01.webp",
    //         "imgMobile": "/images/branner/mobile/04.webp",
    //         "status": 2
    //     },
    //     // {
    //     //     "id": 2,
    //     //     "imgDesktop": "/images/branner/desktop/01.webp",
    //     //     "imgMobile": "/images/branner/mobile/01.webp",
    //     //     "status": 2
    //     // },
    //     {
    //         "id": 3,
    //         "imgDesktop": "/images/branner/desktop/02.webp",
    //         "imgMobile": "/images/branner/mobile/02.webp",
    //         "status": 2
    //     },
    //     {
    //         "id": 4,
    //         "imgDesktop": "/images/branner/desktop/03.webp",
    //         "imgMobile": "/images/branner/mobile/03.webp",
    //         "status": 2
    //     }
    // ];

    const settings = {
        infinite: true,
        // fade: false,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 600,
        autoplaySpeed: 3000,
        arrows: isLargeScreen ? true : false,
        accessibility: true
    };

    const useStyles = makeStyles((theme) => ({
        cardCoverCamp: {
            width: "100%",
            height: "100%",
            cursor: "pointer"
        },
        // cardCoverSearch: {
        //     background: "#202126",
        //     border: "1px solid #5E5F64",
        //     boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        //     borderRadius: "40px",
        //     width: "480px",
        //     [theme.breakpoints.down('xs')]: {
        //         width: "300px"
        //     }
        // },
    }));

    const classes = useStyles();

    const gotoPage = (item) => {
        let page = item.image_link;
        let typeAction = item.image_link_type;

        if(typeAction === 4) {
            let textName = "";
            if (page === "affiliate") {
                dispatch(openGetCredit({
                    tab: "affiliate",
                    show: true,
                    type: null
                }));

                textName = "รับทรัพย์";
            }
            else if (page === "inviteFrined") {
                dispatch(openInviteFriend({
                    show: true
                }));

                textName = "เชิญเพื่อน";
            }

            if(isUserLoggedIn() === false) {
                openBox(textName);
            }
        }
        else if(typeAction === 3) {
            let menuList = dataSetting.menu_camp;
            let dataObj = {};

            dataObj = menuList.find((item, index) => {
                dataObj["id"] = index;
                return item.code === page;
            });

            if(dataObj !== undefined && dataObj !== null) {
                dispatch(setHomeMenuType({
                    id: (dataObj.id - 1),
                    renderPage: dataObj.code,
                    pageTitle: dataObj.title,
                    game_camp_image: dataObj.game_camp_image,
                    column: {
                        desktop: dataObj.column_desktop,
                        mobile: dataObj.column_mobile
                    },
                    typeGame: dataObj.game_camp_type
                }));

                window.scrollTo({
                    top: reduxAppScrollPosition,
                    left: 0,
                    behavior: "smooth"
                });        
            }
        }
        else if(typeAction === 1) {
            window.location.href = page;
        }
        else {
            history.push(page);
        }
    };

    const openBox = (page) => {
        dispatch(setDataAlert({
            type: "warning",
            title: "แจ้งเตือน",
            text: `กรุณาเข้าสู่ระบบก่อนเข้าใช้งาน "${page}"`,
            action: true,
            redirect: "/login",
            show: true,
            buttonText: "ตกลง"
        }));
    };

    useEffect(() => {
        setPathName(window.location.pathname);
    }, [window.location.pathname, path]);

    useEffect(() => {
        if (dataSetting.agent_image !== null) {
            const dataImages = filterImages(dataSetting.agent_image, "slide")
            setDataList(dataImages);
        }
    }, [dataSetting.agent_image]);

    return (
        pathName === "/" && (
            dataList !== null && (
                dataList.length > 0 && (
                    <div id="Branner">
                        <Slider {...settings}>
                            {
                                imagesProcessing ?
                                    dataList.map((item) => {
                                        return (
                                            <div key={item.id}>
                                                <img className={classes.cardCoverCamp} src={isLargeScreen ? item.image_picture : item.picture_mobile} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" onClick={() => gotoPage(item)} />
                                                {/* {
                                                    item.imgDesktop !== null && (
                                                        <img className={classes.cardCoverCamp} src={isLargeScreen ? item.imgDesktop : item.imgMobile} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                                    )
                                                } */}
                                            </div>
                                        )
                                    })
                                    :
                                    <>
                                        {
                                            dataList.length > 0 && (
                                                <div>
                                                    <img className={classes.cardCoverCamp} src={isLargeScreen ? dataList[0].image_picture : dataList[0].picture_mobile} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                                </div>
                                            )
                                        }
                                    </>
                            }
                        </Slider>
                    </div>
                )
            )
        )
    );
}