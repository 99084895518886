import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
// import Slider from 'react-slick';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router-dom';

// Services
import {
    loadGameAll,
    // loadGameAnalyzeAll
} from "./../../../../../../@core/services/gameService";

import { getPromotion } from "../../../../../../@core/services/promotionService";
import { getRank } from "../../../../../../@core/services/rankService";

// @utility
import loadImagesFetch from "./../../../../../../@utility/app/loadImagesFetch";
import alertText from "../../../../../../@utility/app/alertText";

// Redux
import {
    setGameHitAll,
    // setGameHotAll
    // setGameJacpotAll
} from "./../../../../../../@core/redux/actions/game/index";
import { setHomeMenuType } from "./../../../../../../@core/redux/actions/home/index";
import { setDataPosition } from "./../../../../../../@core/redux/actions/app/scrollPosition";
import { setLoadImages } from "./../../../../../../@core/redux/actions/app/processing";
import { loadRank } from './../../../../../../@core/redux/actions/rank';
// import { setLoadHome } from "./../../../../../../@core/redux/actions/app/processing";

// Component
// import ListCard from "./ListCard";
import ListGame from "./ListGame";
import ListRank from "./ListRank";
import ListPromotion from './ListPromotion';
import ListUserRank from '../../iframe-rank/component/ListUserRank';
// import ListGroup from "./ListGroup";
import MenuHomeContent from "./Menu/Home";
import MenuOtherContent from "./Menu/Other";

import SnackbarSet from "../../../components/SnackbarSet";
// import MenuLotteryContent from './Menu/Lottery';

// CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import styles from "./../../../assets/css/Style.module.css";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={(event) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

export default function ContentHome({ processing, loadComponent, setLoadComponent }) {
    const dataCampAll = useSelector(state => state.reduxGame.itemsCampList);
    const dataSetting = useSelector(state => state.reduxAppMenu.menu_items);
    // const renderId = useSelector(state => state.reduxHome.menu.id);
    // const renderPage = useSelector(state => state.reduxHome.menu.renderPage);
    const dataMenu = useSelector(state => state.reduxHome.menu);
    const homeProcessing = useSelector(state => state.reduxAppProcessing.home);
    const imagesProcessing = useSelector(state => state.reduxAppProcessing.images);
    const reduxAppCMS = useSelector(state => state.reduxAppCMS.cms);
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const dispatch = useDispatch();
    const gameListRef = useRef();
    const scrollRef = useRef();
    const [value, setValue] = useState(0);
    const [menuList, setMenuList] = useState(null);
    const history = useHistory();

    const [promotionData, setPromotionData] = useState([]);
    // const [rankData, setRankData] = useState([]);
    // const childRef = useRef();

    const useStyles = makeStyles((theme) => ({
        contentPosition: {
            width: "100%",
            height: "100%"
            // minHeight: "100vh"
        },
        cardBody: {
            background: reduxAppCMS !== null ? reduxAppCMS["bg-foter"] : "black"
            // padding: "0px 4px",
            // marginTop: "30px",
            // [theme.breakpoints.down('xs')]: {
            //     marginTop: "10px"
            // }
        },
        searchInput: {
            width: "90%",
            background: "transparent",
            border: "0px",
            padding: "6px 0px",
            fontSize: "16px",
            color: "white"
        },
        iconSize: {
            marginTop: "6px",
            width: "36px",
            height: "36px",
            color: "white"
        },
        cardOther: {
            [theme.breakpoints.down('xs')]: {
                paddingLeft: "3px",
                paddingRight: "6px"
            }
        },
        heightMenu: {
            height: "100%"
        },
        tabs: {
            height: "930px",
            // marginTop: "-40px",
            [theme.breakpoints.down('xs')]: {
                height: "464px",
                // marginTop: "-25px"
            }
        },
        widthMenuLeft: {
            width: "15%",
            [theme.breakpoints.down('sm')]: {
                width: "22%"
            }
        },
        widthMenuRight: {
            width: "85%",
            [theme.breakpoints.down('sm')]: {
                width: "78%"
            }
        }
        // menuContent: {
        //     minHeight: "134vh",
        //     [theme.breakpoints.down('sm')]: {
        //         minHeight: "58vh"
        //     }
        // },
        // menuPosition: {
        //     position: "absolute"
        // }
        // homeList: {
        //     position: "relative",
        //     background: "white",
        //     borderRadius: "10px",
        //     fontWeight: "600",
        //     fontSize: "20px",
        //     padding: "40px 0px",
        //     marginBottom: "10px",
        //     boxShadow: "0px 0px 5px #CDCDCD",
        //     cursor: "pointer",
        //     '&:hover': {
        //         top: "2px",
        //         background: "linear-gradient(180deg, #8F00FF 0%, #5000B7 100%)",
        //         color: "white"
        //     },
        //     [theme.breakpoints.down('xs')]: {
        //         fontSize: "13px",
        //         marginBottom: "8px",
        //         borderRadius: "6px",
        //         padding: "18px 0px"
        //     }
        // },
        // homeListActive: {
        //     background: "linear-gradient(180deg, #8F00FF 0%, #5000B7 100%)",
        //     color: "white",
        //     borderRadius: "10px",
        //     fontWeight: "600",
        //     fontSize: "20px",
        //     padding: "40px 0px",
        //     marginBottom: "10px",
        //     [theme.breakpoints.down('xs')]: {
        //         fontSize: "13px",
        //         marginBottom: "8px",
        //         borderRadius: "6px",
        //         padding: "18px 0px"
        //     }
        // }
    }));

    const classes = useStyles();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const selectPageHome = (item, index) => {
        alert(index)
        if(item.code === "lottery") {
            history.push(`/games/camp/bowdang/LT-bowdang?play_game=true&redirect=home`);
        }
        else {
            let positionTop = (scrollRef.current.offsetTop) - 100;
            dispatch(setDataPosition(positionTop));
    
            window.scrollTo({
                top: positionTop,
                left: 0,
                behavior: "smooth"
            });

            alert(JSON.stringify({
                id: index,
                renderPage: item.code,
                pageTitle: item.title,
                game_camp_image: item.game_camp_image,
                column: {
                    desktop: item.column_desktop,
                    mobile: item.column_mobile
                },
                typeGame: item.game_camp_type
            }))
    
            dispatch(setHomeMenuType({
                id: index,
                renderPage: item.code,
                pageTitle: item.title,
                game_camp_image: item.game_camp_image,
                column: {
                    desktop: item.column_desktop,
                    mobile: item.column_mobile
                },
                typeGame: item.game_camp_type
            }));
        }
    };

    const renderPageTabs = () => {
        if(dataMenu.renderPage === "home") {
            return <MenuHomeContent></MenuHomeContent>;
        }
        else {
            alert(JSON.stringify(dataMenu))
            return <MenuOtherContent dataCampAll={dataCampAll} isLargeScreen={isLargeScreen}></MenuOtherContent>;
        }
    };

    useEffect(() => {
        const loadDataGameHitAll = () => {
            // loadGameAnalyzeAll("bet-today").then((data) => {
            //     dispatch(setGameHitAll(data));
            // });

            // loadGameAnalyzeAll("bet-week").then((data) => {
            //     dispatch(setGameHotAll(data));
            // });
            loadGameAll("all", 0, "", 10, 0).then((data) => {
                dispatch(setGameHitAll(data));
            });
        };

        getPromotion().then((data) => {
            const statusCode = data.code
            if (statusCode === 0 || statusCode === 200) {
                setPromotionData(data.result);
            }
        });

        dispatch(loadRank())

        if (imagesProcessing) {
            loadDataGameHitAll();
        }
    }, []);

    useEffect(() => {
        if (processing) {
            if (loadComponent === false) {
                setLoadComponent(true);
            }
        }
    }, [processing]);

    useEffect(() => {
        if (homeProcessing) {
            let statusLoad = loadImagesFetch(1);
            if (statusLoad) {
                let positionTop = (scrollRef.current.offsetTop) - 100;
                dispatch(setDataPosition(positionTop));
                
                dispatch(setLoadImages(statusLoad));
            }
        }
    }, [homeProcessing]);

    useEffect(() => {
        if(dataSetting.menu_camp !== null) {
            let menuArr = dataSetting.menu_camp;
            if(menuArr.length > 0) {
                let item = menuArr[0];
                dispatch(setHomeMenuType({
                    id: 0,
                    renderPage: item.code,
                    pageTitle: item.title,
                    game_camp_image: item.game_camp_image,
                    column: {
                        desktop: item.column_desktop,
                        mobile: item.column_mobile
                    },
                    typeGame: item.game_camp_type
                }));
            }
    
            setMenuList(menuArr);
        }
    }, [dataSetting.menu_camp]);

    useEffect(() => {
        if(dataMenu !== null) {
            setValue(dataMenu.id);
        }
    }, [dataMenu]);

    // useEffect(() => {
    //     setValue(renderId);
    // }, [renderId]);

    return (
        <>
            <div className={classes.contentPosition}>
                <div className="boxGame">
                    <div className={classes.cardBody} ref={scrollRef}>
                        <div className={classes.cardOther}>
                            <Grid container spacing={isLargeScreen ? 2 : 1}>
                                <Grid item className={[classes.heightMenu, classes.widthMenuLeft].join(" ")}>
                                    <Tabs
                                        orientation="vertical"
                                        variant="scrollable"
                                        value={value}
                                        onChange={handleChange}
                                        className={classes.tabs}
                                    >
                                        {
                                            (menuList !== null && menuList !== undefined) && (
                                                menuList.map((item, index) => {
                                                    return (
                                                        <LinkTab key={item.id} label={item.name} {...a11yProps(index)} onClick={() => selectPageHome(item, index)} />
                                                    )
                                                })
                                            )
                                        }
                                    </Tabs>
                                </Grid>
                                <Grid item className={classes.widthMenuRight}>
                                    <div className={classes.menuContent}>
                                        {
                                            dataMenu !== null  && (
                                                renderPageTabs()
                                            )
                                        }
                                    </div>
                                </Grid>
                            </Grid>
                        </div>

                        {
                            homeProcessing && (
                                <>
                                    {
                                        reduxAppCMS !== null && (
                                            <>
                                                <br />
                                                {
                                                    (reduxAppCMS?.section_games_rank === "1" || reduxAppCMS?.section_games_rank === 1) && (
                                                        <div className="cardMargin relative" ref={gameListRef}>
                                                            <div className="left">
                                                                <ListRank pageName="10 อันดับยอดนิยมประจำวัน"></ListRank>
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                                {/* {
                                                    (reduxAppCMS?.section_analyze_customer === "1" || reduxAppCMS?.section_analyze_customer === 1) && (
                                                        <div className="cardMargin relative" ref={gameListRef}>
                                                            <div className="left">
                                                                <ListGame pageType="hit" pageName="เกมยอดฮิต"></ListGame>
                                                            </div>
                                                        </div>
                                                    )
                                                } */}

                                                {
                                                    (reduxAppCMS?.section_casino === "1" || reduxAppCMS?.section_casino === 1) && (
                                                        <div className="cardMargin relative" ref={gameListRef}>
                                                            <div className="left">
                                                                <ListGame pageType="casino" pageName="โต๊ะบาคาร่า"></ListGame>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    (reduxAppCMS?.section_promotion === "1" || reduxAppCMS?.section_promotion === 1) && (
                                                        <div className="cardMargin relative" ref={gameListRef}>
                                                            <div className="left">
                                                                <ListPromotion pageName="โปรโมชั่น" promotionData={promotionData}></ListPromotion>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    (reduxAppCMS?.section_user_rank === "1" || reduxAppCMS?.section_user_rank === 1) && (
                                                        <div className="cardMargin relative" ref={gameListRef}>
                                                            <div className="left">
                                                                <ListUserRank pageName="การจัดอันดับรางวัล"></ListUserRank>
                                                            </div>
                                                        </div>
                                                    )
                                                }        
                                            </>
                                        )
                                    }
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    );
}