import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
// import Slider from 'react-slick';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Skeleton from '@material-ui/lab/Skeleton';

// Services
import {
    loadGameAll,
    // loadGameAnalyzeAll
} from "./../../../../../../@core/services/gameService";

// Redux
import {
    setGameHitAll,
    // setGameHotAll
} from "./../../../../../../@core/redux/actions/game/index";

// import styles from "./../../../assets/css/Style.module.css";

export default function SkeletionScreen(props) {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const dispatch = useDispatch();

    const useStyles = makeStyles((theme) => ({
        contentPosition: {
            width: "100%",
            height: "100%"
        },
        cardOther: {
            [theme.breakpoints.down('xs')]: {
                paddingLeft: "6px",
                paddingRight: "6px"
            }
        },
        homeList: {
            borderRadius: "10px",
            padding: "40px 0px",
            marginBottom: "10px",
            background: "white",
            [theme.breakpoints.down('xs')]: {
                padding: "20px 0px"
            }
        },
        homeRight: {
            height: "20vh",
            background: "white",
            marginBottom: "10px",
            borderRadius: "10px"
        },
        homeCardOther: {
            height: "28vh",
            background: "white",
            marginBottom: "10px",
            borderRadius: "10px",
            [theme.breakpoints.down('xs')]: {
                borderRadius: "0px"
            }
        },
        homeCardLast: {
            height: "60vh",
        }
    }));

    const classes = useStyles();

    useEffect(() => {
        const loadDataGameHitAll = () => {
            loadGameAll("all", 0, "", 10, 0).then((data) => {
                dispatch(setGameHitAll(data));
            });
            // loadGameAnalyzeAll("bet-today").then((data) => {
            //     dispatch(setGameHitAll(data));
            // });

            // loadGameAnalyzeAll("bet-week").then((data) => {
            //     dispatch(setGameHotAll(data));
            // });
        };

        // const loadDataGameJackpotAll = () => {
        //     loadGameAnalyzeAll("jackpot").then((data) => {
        //         dispatch(setGameJacpotAll(data));
        //     });
        // };

        loadDataGameHitAll();
        // loadDataGameJackpotAll();
    }, []);

    useEffect(() => {
        if (props.processing === false) {
            if (document.readyState === "complete") {
                props.setProcessing(true);
            }
            else {
                window.addEventListener('load', () => {
                    if (document.readyState === "complete") {
                        props.setProcessing(true);
                    }
                });
            }
        }
    }, [props.processing]);

    return (
        <>
            <div className={classes.contentPosition}>
                <div className="boxGame">
                    <div className={classes.cardOther}>
                        <Grid container spacing={isLargeScreen ? 2 : 1}>
                            <Grid item xs={3} md={2}>
                                <Skeleton animation="wave" variant="rect" className={classes.homeList} />
                                <Skeleton animation="wave" variant="rect" className={classes.homeList} />
                                <Skeleton animation="wave" variant="rect" className={classes.homeList} />
                                <Skeleton animation="wave" variant="rect" className={classes.homeList} />
                                <Skeleton animation="wave" variant="rect" className={classes.homeList} />
                            </Grid>
                            <Grid item xs={9} md={10}>
                                <Skeleton animation="wave" variant="rect" className={classes.homeRight} />
                                <Skeleton animation="wave" variant="rect" className={classes.homeRight} />
                                {/* {
                                        renderPageType === "home" ?
                                            <MenuHomeContent></MenuHomeContent>
                                            :
                                            <MenuOtherContent dataCampAll={dataCampAll} isLargeScreen={isLargeScreen}></MenuOtherContent>
                                    } */}
                            </Grid>
                        </Grid>
                    </div>

                    <div className="cardMargin relative">
                        <Skeleton animation="wave" variant="rect" className={classes.homeCardOther} />
                    </div>
                    <div className="cardMargin relative">
                        <Skeleton animation="wave" variant="rect" className={classes.homeCardOther} />
                    </div>
                    <div className="cardMargin relative">
                        <Skeleton animation="wave" variant="rect" className={[classes.homeCardOther, classes.homeCardLast].join(" ")} />
                    </div>
                </div>
            </div>
        </>
    );
}