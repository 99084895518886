import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

// Auth
import { isUserLoggedIn } from "./../../../../@core/auth/utils";

// Services
import { checkDeposit } from "./../../../../@core/services/depositService";
import { loadHomeData } from "./../../../../@core/services/homeService";

// Redux
import { setHomeData } from "./../../../../@core/redux/actions/home/index";
import { setSnackbarData } from "./../../../../@core/redux/actions/app/snackbar";

export default function NotiEvent() {
    const dispatch = useDispatch();
    let snackbarJson = {};

    const loadBalance = () => {
        loadHomeData().then((data) => {
            const statusCode = data.code;
            if (statusCode === 0 || statusCode === 200) {
                dispatch(setHomeData(data.result));
            }
        });
    };

    const snackbarData = (data) => {
        dispatch(setSnackbarData(data));
    };

    const checkTransactionDeposit = (depositId) => {
        const checkTime = setInterval(() => {
            checkDeposit(depositId).then((data) => {
                const statusCode = data.code;

                if (statusCode === 200 || statusCode === 0) {
                    switch (data.result.status) {
                        case 0:
                        case 1: break;
                        case 2:
                            snackbarJson = {
                                statusShow: true,
                                statusAlert: "success",
                                titleAlert: "แจ้งเตือน",
                                textAlert: "ทำรายการเติมเงินสำเร็จ !"
                            };

                            snackbarData(snackbarJson);
                            clearInterval(checkTime);
                            localStorage.removeItem("processDeposit");
                            loadBalance();
                            break;
                        case 3:
                            snackbarJson = {
                                statusShow: true,
                                statusAlert: "error",
                                titleAlert: "แจ้งเตือน",
                                textAlert: "ทำรายการเติมเงินไม่สำเร็จ ! กรุณาติดต่อผู้ดูแลระบบ"
                            };

                            snackbarData(snackbarJson);
                            clearInterval(checkTime);
                            localStorage.removeItem("processDeposit");
                            break;
                        default:
                            // snackbarJson = {
                            //     statusShow: true,
                            //     statusAlert: "warning",
                            //     titleAlert: "แจ้งเตือน",
                            //     textAlert: "ไม่มีข้อมูลธุรกรรมในระบบ"
                            // };

                            // snackbarData(snackbarJson);
                            clearInterval(checkTime);
                            localStorage.removeItem("processDeposit");
                            break;
                    }
                }
            });
        }, 6000);
    }


    useEffect(() => {
        if (isUserLoggedIn()) {
            const depositId = localStorage.getItem("processDeposit");
            if (depositId !== undefined && depositId !== null) {
                if (isNaN(Number(depositId)) !== true) {
                    checkTransactionDeposit(Number(depositId));
                }
            }
        }
    }, []);

    return null;
}