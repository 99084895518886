import { useState, useEffect, useRef } from 'react';
import Container from '@material-ui/core/Container';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TagManager from 'react-gtm-module';
// import { useRouteMatch } from 'react-router-dom';

// @Utility
// import getCookie from "./../../@utility/app/getCookie";

// Redux
// import { setShowPopupPromotion, setShowPopupLogin, setShowPopupRegister } from "./../../@core/redux/actions/app/popup";

// auth
import { isUserLoggedIn } from "./../../@core/auth/utils";

// Hooks
import useParameterUrl from "./../../@utility/hooks/useParameterUrl";

// Services
import { loadHomeData } from "../../@core/services/homeService";

// Redux
import { setHomeData } from "./../../@core/redux/actions/home/index";
import { 
    setLoadHome,
    setLoadImages
} from "./../../@core/redux/actions/app/processing";
import { setDataCMS } from "./../../@core/redux/actions/app/cms";

// Routes
import VerticalRoutes from "./vertical/VerticalRoutes";

// Component
import Navbar from "./vertical/components/Navbar/index";
import FooterMenu from "./vertical/components/Footer/index";
import BrannerSlide from "./vertical/components/BrannerSlide";
import TextAnimate from "./vertical/components/TextAnimate";
import NavbarMenu from "./vertical/components/NavbarMenu";
// import LoginApp from "./vertical/components/Login";
// import RegisterApp from "./vertical/components/Register";
import SnackbarApp from "./vertical/components/Alert/Snackbar";
import SnackbarSet from "./vertical/components/SnackbarSet";
import DialogAction from "./vertical/components/DialogAction";
import LoadSpinner from "./vertical/components/LoadSpinner";
import ConditionPopup from "./vertical/components/ConditionPopup";
import Contact from "./vertical/components/Contact";
// import HorizontalScreen from "./../vertical/pages/horizontal/index";
// import PopupApp from "./vertical/components/Popup";
import ConfirmApp from "./vertical/components/Confirm";
import AlertApp from "./vertical/components/Alert/index";
import FooterHome from "./vertical/components/Footer";
import ListContact from "./vertical/components/ListContact";
import LoadSkeletonScreen from "./vertical/components/LoadSkeletonScreen";
import AffiateApp from "./vertical/pages/affiate/index";
import InviteApp from "./vertical/pages/invite/index";

const Layouts = (action) => {
    // const { path } = useRouteMatch();
    const [loadRoutes, setLoadRoutes] = useState(false);
    // const [settingList, setSettingList] = useState([]);
    const dataSetting = useSelector(state => state.reduxAppMenu.menu_items);
    const dialogData = useSelector(state => state.reduxAppDialog.dialogData);
    const conditionData = useSelector(state => state.reduxAppCondition.conditionData);
    const contactStatus = useSelector(state => state.reduxAppContact.statusShow);
    const reduxSpinner = useSelector(state => state.reduxAppSpinner.data);
    // const popupPromotionShow = useSelector(state => state.reduxAppPopup.popupPromotion);
    // const popupLoginShow = useSelector(state => state.reduxAppPopup.popupLogin);
    // const popupRegisterShow = useSelector(state => state.reduxAppPopup.popupRegister);
    const confirmData = useSelector(state => state.reduxAppConfirm);
    const alertData = useSelector(state => state.reduxAppAlert);
    // const homeProcessing = useSelector(state => state.reduxAppProcessing.home);
    const imagesProcessing = useSelector(state => state.reduxAppProcessing.images);
    const childRef = useRef();
    const statusShow = useSelector(state => state.reduxAppSnackbar.snackbarData.statusShow);
    const affiliate = useSelector(state => state.reduxAffiliate.affiliate);
    const linkFriend = useSelector(state => state.reduxAffiliate.linkFriend);
    const dispatch = useDispatch();
    // const { screenWidth, screenHeight } = useWindowSize();
    // const [popupPromotion, setPopupPromotion] = useState(null);
    // const [popupLogin, setPopupLogin] = useState(null);
    // const [popupRegister, setPopupRegister] = useState(null);
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
    const [pathName, setPathName] = useState("/");
    const [processing, setProcessing] = useState(false);
    const [processingNavbar, setProcessingNavbar] = useState(false);
    // const { path } = useRouteMatch();
    
    const parsed = useParameterUrl();
    const { iframe_load } = parsed;
    const [iframeStatus, setIframeStatus] = useState(false);
    // const parsed = useParameterUrl();
    // const { play_game } = parsed;

    const renderAlertBar = () => {
        if (statusShow === false) return null;

        return (
            <SnackbarApp></SnackbarApp>
        );
    };

    // const renderPopup = (popupData, typePopup) => {
    //     if (popupData === null) {
    //         return null;
    //     }
    //     else {
    //         if (popupData.length > 0) {
    //             if (typePopup === "promotion") {
    //                 if (Number(getCookie("promotionPopup")) === 1) {
    //                     return null;
    //                 }
    //                 else {
    //                     if (pathName === "/") {
    //                         return <PopupApp popupData={popupData} typePopup={typePopup} />
    //                     }
    //                     else {
    //                         return null;
    //                     }
    //                 }
    //             }
    //             else {
    //                 return <PopupApp popupData={popupData} typePopup={typePopup} />
    //             }
    //         }
    //         else {
    //             dispatch(setShowPopupPromotion(false));
    //             dispatch(setShowPopupLogin(false));
    //             dispatch(setShowPopupRegister(false));

    //             return null;
    //         }
    //     }
    // };

    const setLoadDataCms = (dataCms) => {
        dispatch(setDataCMS(dataCms));
        localStorage.setItem("data_cms", JSON.stringify(dataCms));
        document.getElementsByTagName("body")[0].style.background = dataCms["bg-foter"];
        document.documentElement.style.setProperty("--bg-card", dataCms["bg-card"]);
        document.documentElement.style.setProperty("--bg-foter", dataCms["bg-foter"]);
        document.documentElement.style.setProperty("--bg-main", dataCms["bg-main"]);
        document.documentElement.style.setProperty("--bg-modal", dataCms["bg-modal"]);
        document.documentElement.style.setProperty("--bg-navbar", dataCms["bg-navbar"]);
        document.documentElement.style.setProperty("--bg-popup", dataCms["bg-popup"]);
        document.documentElement.style.setProperty("--bg-text-animation", dataCms["bg-text-animation"]);
        document.documentElement.style.setProperty("--bt-confirm", dataCms["bt-confirm"]);
        document.documentElement.style.setProperty("--bt-danger", dataCms["bt-danger"]);
        document.documentElement.style.setProperty("--bt-defult", dataCms["bt-defult"]);
        document.documentElement.style.setProperty("--bt-info", dataCms["bt-info"]);
        document.documentElement.style.setProperty("--bt-login", dataCms["bt-login"]);
        document.documentElement.style.setProperty("--bt-register", dataCms["bt-register"]);
        document.documentElement.style.setProperty("--bt-secondary", dataCms["bt-secondary"]);
        document.documentElement.style.setProperty("--bt-sucess", dataCms["bt-sucess"]);
        document.documentElement.style.setProperty("--bt-undefult", dataCms["bt-undefult"]);
        document.documentElement.style.setProperty("--bt-warning", dataCms["bt-warning"]);

        if(dataCms["google_key"] !== "") {
            TagManager.initialize({ gtmId: dataCms["google_key"] });
        }

        let url_domain = `${window.location.protocol}//${window.location.host}`;
        let myDynamicManifest = {
            "short_name": dataCms["manifest_short_name"],
            "name": dataCms["manifest_name"],
            "icons": [
                {
                "src": dataCms["manifest_icon_xl"],
                "type": "image/png",
                "sizes": "512x512"
                },
                {
                "src": dataCms["manifest_icon_lg"],
                "type": "image/png",
                "sizes": "192x192"
                },
                {
                "src": dataCms["manifest_icon_md"],
                "type": "image/png",
                "sizes": "128x128"
                },
                {
                "src": dataCms["manifest_icon_sm"],
                "type": "image/png",
                "sizes": "96x96"
                },
                {
                "src": dataCms["manifest_icon_xs"],
                "type": "image/png",
                "sizes": "64x64"
                }
            ],
            "scope": dataCms["manifest_scope"],
            "start_url": url_domain,
            "display": dataCms["manifest_display"],
            "theme_color": dataCms["manifest_theme_color"],
            "background_color": dataCms["manifest_background_color"]
        };
            
        const stringManifest = JSON.stringify(myDynamicManifest);
        const blob = new Blob([stringManifest], {type: 'application/json'});
        const manifestURL = URL.createObjectURL(blob);
        document.querySelector("#manifest-project").setAttribute("href", manifestURL);
        document.querySelector("#apple-touch-project").setAttribute("href", dataCms["manifest_icon_lg"]);
    };

    useEffect(() => {
        localStorage.setItem("allusion", document.referrer);
        setPathName(window.location.pathname);
        setLoadRoutes(true);
        // setSettingList(dataSetting);

        // if (dataSetting?.agent_popup !== undefined) {
        //     if (dataSetting.agent_popup.length > 0) {
        //         let promotionData = dataSetting.agent_popup.filter((item) => {
        //             return item.popup_position === "promotion";
        //         });

        //         let loginData = dataSetting.agent_popup.filter((item) => {
        //             return item.popup_position === "login";
        //         });

        //         let registerData = dataSetting.agent_popup.filter((item) => {
        //             return item.popup_position === "register";
        //         });

        //         setPopupPromotion(promotionData);
        //         setPopupLogin(loginData);
        //         setPopupRegister(registerData);
        //     }
        // }

        if (pathName !== "/") {
            if (isUserLoggedIn()) {
                loadHomeData().then((data) => {
                    if (data.code === 0 || data.code === 200) {
                        dispatch(setHomeData(data.result));
                    }
                });
            }

            dispatch(setLoadHome(true));
            dispatch(setLoadImages(true));
        }
    }, [pathName]);

    useEffect(() => {
        if(dataSetting.cms !== null) {
            localStorage.removeItem("data_cms");
            const dataCms = dataSetting.cms;
            setLoadDataCms(dataCms);
            setProcessing(true);
            setProcessingNavbar(true);
        }
        else {
            let checkData = localStorage.getItem("data_cms");
            if(checkData !== undefined) {
                let data_cms = JSON.parse(checkData);
                if(data_cms !== undefined && data_cms !== null) {
                    setLoadDataCms(data_cms);
                    setProcessingNavbar(true);
                }
                else {
                    setProcessingNavbar(false);
                }
            }
        }
    }, [dataSetting.cms]);

    useEffect(() => {
        if(iframe_load === true || iframe_load === "true") {
            setIframeStatus(true);
        }
        else {
            setIframeStatus(false);
        }
    }, [iframe_load]);

    // console.log(
    //     `processingNavbar ${processingNavbar}, `, 
    //     `processing ${processing}, `,
    //     `imagesProcessing ${imagesProcessing}, `
    // )

    return <>
        {
            loadRoutes && (
                iframeStatus ? 
                    <Container maxWidth="lg" className="bgContainer">
                        <VerticalRoutes dataSetting={dataSetting}></VerticalRoutes>
                    </Container>
                :
                <>
                    {
                        processingNavbar && (
                            <Navbar isUserLoggedIn={isUserLoggedIn()} isLargeScreen={isLargeScreen}></Navbar>
                            // (play_game !== true &&  play_game !== "true") && (
                                
                            // )
                        )
                    }

                    {/* <Navbar isUserLoggedIn={isUserLoggedIn()} isLargeScreen={isLargeScreen}></Navbar> */}

                    <div className="homePosition">
                        <BrannerSlide isLargeScreen={isLargeScreen}></BrannerSlide>
                        <TextAnimate></TextAnimate>
                    </div>

                    {
                        isUserLoggedIn() && (
                            <NavbarMenu isUserLoggedIn={isUserLoggedIn()} isLargeScreen={isLargeScreen}></NavbarMenu>
                        )
                    }

                    <Container maxWidth="lg" className="bgContainer">
                        <div className={isUserLoggedIn() ? "wrapperActive" : "wrapper"}>
                            {
                                action.loadRoutes && (
                                    <VerticalRoutes dataSetting={dataSetting}></VerticalRoutes>
                                )
                            }

                            {
                                processing && (
                                    imagesProcessing && (
                                        <div className="cardMargin relative">
                                            <div className="left">
                                                <ListContact isLargeScreen={isLargeScreen}></ListContact>
                                            </div>
                                            <div className="cardMargin relative" style={{ marginTop: "0px" }}>
                                                <div className="left">
                                                    <FooterHome></FooterHome>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )
                            }
                        </div>
                    </Container>

                    {
                        processingNavbar && (
                            <FooterMenu isUserLoggedIn={isUserLoggedIn()} isLargeScreen={isLargeScreen}></FooterMenu>
                        )
                    }
                    
                    {/* <FooterMenu isUserLoggedIn={isUserLoggedIn()} isLargeScreen={isLargeScreen}></FooterMenu> */}
                </>
            )
        }

        {
            (loadRoutes === false || processing === false) && <LoadSkeletonScreen></LoadSkeletonScreen>
        }

        {/* {
            openRegister && (<RegisterApp></RegisterApp>)
        } */}

        {
            renderAlertBar()
        }

        {
            dialogData.statusShow && (
                <DialogAction dialogData={dialogData}></DialogAction>
            )
        }

        {
            conditionData.statusShow && (
                <ConditionPopup conditionData={conditionData}></ConditionPopup>
            )
        }

        {
            contactStatus && (<Contact></Contact>)
        }

        {
            reduxSpinner.status && (
                <LoadSpinner textProcess={reduxSpinner.textProcess}></LoadSpinner>
            )
        }

        {/* {
            popupPromotionShow && (
                renderPopup(popupPromotion, "promotion")
            )
        }

        {
            popupLoginShow && (
                renderPopup(popupLogin, "login")
            )
        }

        {
            popupRegisterShow && (
                renderPopup(popupRegister, "register")
            )
        } */}

        {
            confirmData.show && (
                <ConfirmApp confirmData={confirmData}></ConfirmApp>
            )
        }

        {
            alertData.show && (
                <AlertApp alertData={alertData}></AlertApp>
            )
        }

        {
            isUserLoggedIn && (
                <>
                    {
                        affiliate.show && (
                            <AffiateApp affiliate={affiliate} />
                        )
                    }

                    {
                        linkFriend.show && (
                            <InviteApp linkFriend={linkFriend} />
                        )
                    }
                </>
            )
        }

        <SnackbarSet ref={childRef} />
    </>;
}

export default Layouts;