import axios from 'axios';
import serviceConfig from "./serviceConfig";
import { getToken } from "./../auth/utils";
import { checkStatusCode } from "./services";

const svConfig = { ...serviceConfig };

const changePassword = async (data) => {
    const {
        password_old,
        password,
        password_confirm
    } = data;

    let myJson = new URLSearchParams({
        "password_old": password_old,
        "password": password,
        "password_confirm": password_confirm
    });

    let config = {
        method: svConfig.method_post,
        url: `/customer/profile/reset-password`,
        headers: {
            "Authorization": `${svConfig.headerType} ${getToken()}`,
            "Content-Type": svConfig.contentType
        },
        data: myJson
    };

    const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response);
    const { code } = dataPromise;

    if (checkStatusCode(code)) {
        if (code === undefined) {
            return 500;
        }
        else {
            return code;
        }
    }
}

export {
    changePassword
};