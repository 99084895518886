import axios from 'axios';
import serviceConfig from "./serviceConfig";
import { getToken } from "./../auth/utils";
import { isMobile } from 'react-device-detect';
import { isUserLoggedIn } from "./../auth/utils";
import { checkStatusCode } from "./services";

const svConfig = { ...serviceConfig };

const loadCampAll = async (type) => {
  let config = {
    method: svConfig.method_get,
    url: `/general/list/camp/${type}`,
    headers: {
      "Authorization": `${svConfig.headerType} ${getToken()}`,
      "Content-Type": svConfig.contentType
    },
    data: {}
  };

  const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
  const { code, result } = dataPromise;

  if (checkStatusCode(code)) {
    if (code === 0) {
      return result;
    }
    else {
      return [];
    }
  }
}

const loadCategoryCampAll = async () => {
  let config = {
    method: svConfig.method_get,
    url: `/general/list/camp/category`,
    headers: {
      "Authorization": `${svConfig.headerType} ${getToken()}`,
      "Content-Type": svConfig.contentType
    },
    data: {}
  };

  const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
  const { code, result } = dataPromise;

  if (checkStatusCode(code)) {
    if (code === 0) {
      return result;
    }
    else {
      return [];
    }
  }
}

const loadGameAll = async (camp, type, filter, limit = -1, offset = 0) => {
  let myJson = new URLSearchParams({
    "filter": filter,
    "limit": limit,
    "offset": offset
  });

  let config = {
    method: svConfig.method_post,
    url: `/general/list/game/${camp}/${type}`,
    headers: {
      "Authorization": `${svConfig.headerType} ${getToken()}`,
      "Content-Type": svConfig.contentType
    },
    data: myJson
  };

  const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
  const { code, result } = dataPromise;

  if (checkStatusCode(code)) {
    if (code === 0) {
      return result;
    }
    else {
      return [];
    }
  }
}

const loadGameCamp = async (camp, type = 0, filter = "", limit = -1, offset = 0, specials) => {
  let myJson = new URLSearchParams({
    "camp": camp,
    "type": type,
    "filter": filter,
    "limit": limit,
    "offset": offset,
    "specials": specials
  });

  let config = {
    method: svConfig.method_post,
    url: `/general/list/games`,
    headers: {
      "Authorization": `${svConfig.headerType} ${getToken()}`,
      "Content-Type": svConfig.contentType
    },
    data: myJson
  };

  const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
  const { code, result } = dataPromise;

  if (checkStatusCode(code)) {
    if (code === 0 || code === 23) {
      return result;
    }
    else {
      return null;
    }
  }
}

const loadGameAnalyzeAll = async (analyze) => {
  let config = {
    method: svConfig.method_get,
    url: `/general/list/analyze/${analyze}`,
    headers: {
      "Authorization": `${svConfig.headerType} ${getToken()}`,
      "Content-Type": svConfig.contentType
    },
    data: {}
  };

  const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
  const { code, result } = dataPromise;

  if (checkStatusCode(code)) {
    if (code === 0) {
      return result;
    }
    else {
      return [];
    }
  }
}

const loadGameLikeAll = async () => {
  let config = {
    method: svConfig.method_get,
    url: `/general/list/game/like`,
    headers: {
      "Authorization": `${svConfig.headerType} ${getToken()}`,
      "Content-Type": svConfig.contentType
    },
    data: {}
  };

  const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
  const { code, result } = dataPromise;

  if (checkStatusCode(code)) {
    if (code === 0) {
      return result;
    }
    else {
      return [];
    }
  }
}

const likeGame = async (data) => {
  const {
    gameId,
    statusLike
  } = data;

  let myJson = new URLSearchParams({
    "game_id": gameId
  });

  let config = {};


  if (statusLike === true) {
    config = {
      method: svConfig.method_put,
      url: `/customer/profile/game-like`,
      headers: {
        "Authorization": `${svConfig.headerType} ${getToken()}`,
        "Content-Type": svConfig.contentType
      },
      data: myJson
    };
  }
  else {
    config = {
      method: svConfig.method_delete,
      url: `/customer/profile/game-like/${gameId}`,
      headers: {
        "Authorization": `${svConfig.headerType} ${getToken()}`,
        "Content-Type": svConfig.contentType
      },
      data: {}
    };
  }

  const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
  const { code } = dataPromise;

  if (checkStatusCode(code)) {
    return {
      code
    };
  }
}

const openGame = async (camp, game_code) => {
  let mode = "demo"
  if (isUserLoggedIn()) {
    mode = "real"
  }
  let myJson = new URLSearchParams({
    "game_code": game_code,
    "mode": mode,
    "is_mobile": isMobile,
    "camp": camp,
  });

  let config = {
    method: svConfig.method_post,
    url: "/general/generate/open-game",
    headers: {
      "Authorization": `${svConfig.headerType} ${getToken()}`,
      "Content-Type": svConfig.contentType
    },
    data: myJson
  };

  const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response);
  const { code, result } = dataPromise;

  if (checkStatusCode(code)) {
    return {
      code,
      result
    };
  }
}

const loadGameLastAll = async () => {
  let config = {
    method: svConfig.method_get,
    url: `/customer/profile/lastgame`,
    headers: {
      "Authorization": `${svConfig.headerType} ${getToken()}`,
      "Content-Type": svConfig.contentType
    },
    data: {}
  };

  const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
  const { code, result } = dataPromise;

  if (checkStatusCode(code)) {
    if (checkStatusCode(code)) {
      if (code === 0) {
        return result === null ? [] : result;
      }
      else {
        return [];
      }
    }
  }
}

const loadGameCategory = async (camp) => {
  let myJson = new URLSearchParams({
    "camp": camp
  });

  let config = {
    method: svConfig.method_post,
    url: `/general/list/camp/detail`,
    headers: {
      "Authorization": `${svConfig.headerType} ${getToken()}`,
      "Content-Type": svConfig.contentType
    },
    data: myJson
  };

  const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);
  const { code, result } = dataPromise;

  if (checkStatusCode(code)) {
    if (code === 0) {
      return result;
    }
    else {
      return null;
    }
  }
}

export {
  loadCampAll,
  loadCategoryCampAll,
  loadGameAll,
  loadGameCamp,
  loadGameAnalyzeAll,
  openGame,
  loadGameLikeAll,
  likeGame,
  loadGameLastAll,
  loadGameCategory
};